import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import "./index.css";
import { Helmet } from "react-helmet";
import App from "./App";
import { BASE_WEB_TITLE } from "./components/Public/Util";
import * as serviceWorker from "./serviceWorker";
import GlobalContext from "./GlobalContext";

const pageRoute = {
  HOME: "/",
  MEDIA: "/media",
  ALL_MEDIA: "/allmedia",
  ABOUT: "/about",
  NEWS: "/news",
  CONTACT: "/contact-us",
  ASSIST: "/assist",
  PASAR: "/pasar",
  PASAR_INNER: "/pasar/",
};

let currentActive = "home";

const pageInfoMap = {
  "/": {
    title: "Boost Credit: Micro Financing & Insurance in Malaysia | An Axiata Company",
    meta:
      "Boost Credit offers easy financing & insurance for all. Apply online for SME financing without repayment of principal for the first 3 months under our Capital Plus platform",
  },
  "/media": {
    title: "Media | Boost Credit",
    meta: "Get the latest news and press releases on Boost Credit here.",
  },
  "/about": {
    title: "About Boost Credit - Who We Are | Boost Credit",
    meta:
      "Boost Credit is the micro-financing and micro-insurance brand by Axiata Digital, the digital services arm of Axiata Group. We champion MSMEs in Southeast Asia.",
  },
  "/contact-us": {
    title: "Contact Us | Boost Credit",
    meta:
      "For any enquiries, reach out to us at +603 2960 9404 or email us at creditinfo@myboost.co.",
  },
  "/assist": {
    title: "Aspirasi Assist | Syariah-compliant Micro-financing in Malaysia",
    meta:
      "We are committed to improving the well-being of our world too. Apply online today for SME financing with no repayment for the first 6 months.",
  },
  "/pasar": {
    title: "Boost Credit: One-stop Centre for Micro-Financing & Insurance",
    meta:
      "Welcome to your one-stop solution! Browse the all-in-one store for quick and easy financing & insurance solutions from Boost Credit.",
  },
  "/kongsiklub": {
    title: "Kongsi Klub by Boost Credit",
    meta: "Boost Credit"
  }
};

const setWebTitle = (subPath) => {
  return `${BASE_WEB_TITLE} | ${subPath}`;
};

export default function ScrollToTop(context) {
  const { context: globalContext } = context;

  const { pathname } = useLocation();
  const location = useLocation();

  let { pageTitle } = globalContext;

  if (pageTitle) {
    pageTitle = pageTitle.replace(/<[^>]*>/g, " ");
  }

  const { setPageTitle } = globalContext;

  const { pageMeta } = globalContext;
  const { setPageMeta } = globalContext;

  const { pageImage } = globalContext;
  const { setPageImage } = globalContext;

  useEffect(() => {
    currentActive = pathname;
    if (pathname === pageRoute.HOME) {
      globalContext.setCurrentActive("home");
    } else if (
      pathname.includes(pageRoute.MEDIA)
      || pathname.includes(pageRoute.ALL_MEDIA)
    ) {
      globalContext.setCurrentActive("media");
    } else if (pathname.includes(pageRoute.NEWS)) {
      globalContext.setCurrentActive("news");
    } else if (pathname.includes(pageRoute.ABOUT)) {
      globalContext.setCurrentActive("about");
    } else if (pathname.includes(pageRoute.CONTACT)) {
      globalContext.setCurrentActive("contact");
    } else if (pathname.includes(pageRoute.ASSIST)) {
      globalContext.setCurrentActive("assist");
    } else if (pathname.includes(pageRoute.PASAR_INNER)) {
      globalContext.setCurrentActive("pasar_inner");
    } else if (pathname.includes(pageRoute.PASAR)) {
      globalContext.setCurrentActive("pasar");
    }

    const currentPageInfo = pageInfoMap[pathname] || {};
    const { title, meta } = currentPageInfo;
    const defaultImage = "/favicon-boost.png";

    setPageTitle(title);
    setPageMeta(meta);
    setPageImage(defaultImage);

    window.scrollTo(0, 0);
  }, [pathname]);

  const url = window.location.href;

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageMeta} />
      <meta
        name="robots"
        content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <link rel="canonical" href={url} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageMeta} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={pageTitle} />
      <meta property="og:image" content={pageImage} />
      <meta property="og:image:secure_url" content={pageImage} />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={pageMeta} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:image" content={pageImage} />
    </Helmet>
  );
}

function RenderApp() {
  const [currentActive, setCurrentActive] = useState("home");
  const initialPageInfo = pageInfoMap["/"];
  const [pageTitle, setPageTitle] = useState(initialPageInfo.title);
  const [pageMeta, setPageMeta] = useState(initialPageInfo.meta);
  const [pageImage, setPageImage] = useState("/favicon-boost.png");
  const [headerAds, setHeaderAds] = useState({
    show: true,
    icon: "",
    text: "REMINDER: Black Lives Matter!",
    color: "#333",
    background: "#ccc",
  });
  const [headerAction, setHeaderAction] = useState("");

  const [sidePopup, setSidePopup] = useState({
    show: true,
    position: "bottom right",
    img: "",
  });

  const globalState = {
    currentActive: currentActive,
    setCurrentActive: setCurrentActive,
    pageTitle: pageTitle,
    setPageTitle: setPageTitle,
    pageMeta: pageMeta,
    setPageMeta: setPageMeta,
    pageImage: pageImage,
    setPageImage: setPageImage,
    headerAds: headerAds,
    headerAction: headerAction,
    setHeaderAction: setHeaderAction,
    setHeaderAds: setHeaderAds,
    sidePopup: sidePopup,
    setSidePopup: setSidePopup,
  };

  return (
    <GlobalContext.Provider value={globalState}>
      <Router>
        <GlobalContext.Consumer>
          {(context) => {
            return <ScrollToTop context={context} />;
          }}
        </GlobalContext.Consumer>

        <App />
      </Router>
    </GlobalContext.Provider>
  );
}

ReactDOM.render(
  // <React.StrictMode>
  <RenderApp />,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
