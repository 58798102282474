import React from "react";

const GlobalContext = React.createContext({
  currentActive: "home",
  setCurrentActive: () => {},
  pageTitle: "",
  setPageTitle: () => {},
  pageMeta: "",
  setPageMeta: () => {},
  pageImage: "",
  setPageImage: () => {},
  headerAds: {
    show: true,
    icon: "",
    text: "REMINDER: Black Lives Matter!",
    color: "#333",
    background: "#ccc"
  },
  setHeaderAds: () => {},
  headerAction: "",
  sidePopup: {
    show: true,
    position: "top left",
    img: ""
  },
  setSidePopup: () => {}
});

export default GlobalContext;
