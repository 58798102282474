import * as React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import { FooterWrapper } from "./Styled";
// import { Link } from "react-router-dom";
import Link from "../Util/Link";
import GlobalContext from "../../../GlobalContext";

class Footer extends React.Component {
  render() {
    return (
      <GlobalContext.Consumer>
        {(globalContext) => {
          return (
            <FooterWrapper>
              <div className="wrapper">
                {globalContext.headerAction === "" ? (
                  <>
                    <div className="footer-item center">
                      <h3 className="footer-title">Pasar Boost Credit</h3>
                      {/* <Link className="footer-item-link" to={"/"}> */}
                      {/*  How the store works */}
                      {/* </Link> */}
                      <Link className="footer-item-link" to="/pasar">
                        Go to Pasar
                      </Link>
                      <Link
                        className="footer-item-link"
                        to="/pasar#micro-financing"
                      >
                        Micro-financing
                      </Link>
                      <Link
                        className="footer-item-link"
                        to="/pasar#micro-insurance"
                      >
                        Micro-insurance
                      </Link>
                    </div>

                    <div className="footer-item center">
                      <h3 className="footer-title">Get to know us</h3>
                      <Link className="footer-item-link" to="/about">
                        Who we are
                      </Link>
                      <a
                        className="footer-item-link"
                        href="https://linkedin.com/company/aspirasi/jobs/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Join us!
                      </a>
                      <Link className="footer-item-link" to="/media">
                        Media
                      </Link>
                    </div>

                    <div className="footer-item center">
                      <h3 className="footer-title">Connect with us</h3>
                      <a
                        className="footer-item-link"
                        href="https://www.facebook.com/myboostcredit/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaFacebookF />
                        Facebook
                      </a>
                      <a
                        className="footer-item-link"
                        href="https://www.instagram.com/myboostcredit/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaInstagram />
                        Instagram
                      </a>
                      <a
                        className="footer-item-link"
                        href="https://www.linkedin.com/company/aspirasi"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaLinkedinIn />
                        LinkedIn
                      </a>
                      <a
                        className="footer-item-link"
                        href="https://www.youtube.com/channel/UCR-UFeVSCAl0SUTu0EqjF7A"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaYoutube />
                        YouTube
                      </a>
                    </div>

                    <div className="footer-item center">
                      <h3 className="footer-title">Contact Us</h3>
                      <a
                        className="footer-item-link"
                        href="mailto:creditinfo@myboost.co"
                        target="_blank"
                        rel="noreferrer"
                      >
                        creditinfo@myboost.co
                      </a>
                      <span className="footer-item-link">+603 2260 9404</span>
                      <span className="footer-item-link">
                        Axiata Tower, Level 32,
                        <br />
                        9 Jalan Stesen Sentral 5,
                        <br />
                        Kuala Lumpur Sentral,
                        <br />
                        50470 Kuala Lumpur,
                        <br />
                        Malaysia.
                      </span>
                    </div>

                    <div className="footer-item">
                      <h3 className="footer-title">Newsletter</h3>
                      <span className="footer-item-link">
                        Give your inbox the Credit Boost it needs.
                      </span>
                      <form
                        action="https://aspirasi.us4.list-manage.com/subscribe/post?u=2bcbf1ffd4eea2402d68ff658&amp;id=4daf879eb7"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        className="validate"
                        target="_blank"
                        noValidate
                      >
                        <div className="input-wrapper">
                          <div
                            aria-hidden="true"
                            style={{ position: "absolute", left: -99999 }}
                          >
                            <input
                              readOnly
                              type="text"
                              name="b_2bcbf1ffd4eea2402d68ff658_4daf879eb7"
                              tabIndex="-1"
                              value=""
                            />
                          </div>
                          <div className="input">
                            <input
                              type="email"
                              // value=""
                              name="EMAIL"
                              className="email"
                              id="mce-EMAIL"
                              placeholder="Email"
                              required
                            />
                          </div>
                          <button type="submit">Subscribe</button>
                        </div>
                      </form>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="footer-item center">
                      <h3 className="footer-title">Contact Us</h3>
                      <a
                        className="footer-item-link"
                        href="mailto:creditinfo@myboost.co"
                        target="_blank"
                        rel="noreferrer"
                      >
                        creditinfo@myboost.co
                      </a>
                      <span className="footer-item-link">+603 2260 9404</span>
                    </div>
                    <div className="footer-item center">
                      <h3 className="footer-title">&nbsp;</h3>
                      <span className="footer-item-link">
                        Axiata Tower, Level 32,
                        <br />
                        9 Jalan Stesen Sentral 5,
                        <br />
                        Kuala Lumpur Sentral,
                        <br />
                        50470 Kuala Lumpur,
                        <br />
                        Malaysia.
                      </span>
                    </div>

                    <div className="footer-item center">
                      <h3 className="footer-title">Connect with us</h3>
                      <a
                        className="footer-item-link"
                        href="https://www.facebook.com/myboostcredit/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaFacebookF />
                        Facebook
                      </a>
                      <a
                        className="footer-item-link"
                        href="https://www.instagram.com/myboostcredit/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaInstagram />
                        Instagram
                      </a>
                    </div>

                    <div className="footer-item center">
                      <h3 className="footer-title">&nbsp;</h3>
                      <a
                        className="footer-item-link"
                        href="https://www.linkedin.com/company/myboostcredit/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaLinkedinIn />
                        LinkedIn
                      </a>
                      <a
                        className="footer-item-link"
                        href="https://www.youtube.com/channel/UCR-UFeVSCAl0SUTu0EqjF7A"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaYoutube />
                        YouTube
                      </a>
                    </div>

                    <div className="footer-item center" />
                  </>
                )}
              </div>
              <div className="bottom-footer-wrapper">
                <div className="wrapper bottom-footer">
                  <div className="bottom-footer-item-wrapper">
                    {/* <Link className="bottom-footer-item" to={"/"}>
                      Terms of Service
                    </Link>
                    <Link className="bottom-footer-item" to={"/"}>
                      Privacy Notice
                    </Link> */}
                    <span className="bottom-footer-item">
                      Axiata Digital Capital Sdn. Bhd. (201601006143)
                      <a
                        className="bottom-footer-item-link"
                        href="https://aspirasi-assets.s3.ap-southeast-1.amazonaws.com/boost-credit/PRN_Boost_Credit.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Notice
                      </a>
                    </span>
                    <span className="bottom-footer-item">
                      &copy;2022 Boost Credit | All rights reserved.
                    </span>
                  </div>
                </div>
              </div>
            </FooterWrapper>
          );
        }}
      </GlobalContext.Consumer>
    );
  }
}

export default Footer;
