import { lazy } from "react";

import {
  PRODUCT_TRAVEL_INTERNATIONAL_PATH,
  PRODUCT_TRAVEL_DOMESTIC_PATH,
  PRODUCT_HOSPITAL_CASH_PATH,
  PRODUCT_BILLPROTECT_PATH,
  PRODUCT_CARDPROTECT_PATH,
  PRODUCT_PROTECT_SUPER_6_PATH,
  PRODUCT_TAKAFUL_USAHAWAN_3_IN_1_PATH,
  PRODUCT_SME_OWNERPROTECT_PATH,
  PRODUCT_PROTECT_PATH,
  PRODUCT_PROTECT_BOOST_PATH,
  PRODUCT_CELCOM_CPROTECT_PATH,
  PRODUCT_CELCOM_CPROTECT_RX_PATH,
  PRODUCT_CANCER_CARE_PRIME_PATH,
} from "../utils";

const Journey = lazy(() => import(".."));
const CProtect = lazy(() => import("../CProtect"));
const JourneyCancerCarePrime = lazy(() => import("../../JourneyCancerCarePrime"));
const JourneyRenew = lazy(() => import("../JourneyRenew"));

const routes = [
  {
    path: `/pasar/${PRODUCT_TRAVEL_INTERNATIONAL_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/pasar/${PRODUCT_TRAVEL_DOMESTIC_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/pasar/${PRODUCT_HOSPITAL_CASH_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/pasar/${PRODUCT_HOSPITAL_CASH_PATH}/:code`,
    exact: true,
    component: JourneyRenew,
  },
  {
    path: `/pasar/${PRODUCT_BILLPROTECT_PATH}/:code`,
    exact: true,
    component: JourneyRenew,
  },
  {
    path: `/pasar/${PRODUCT_CARDPROTECT_PATH}/:code`,
    exact: true,
    component: JourneyRenew,
  },
  {
    path: `/pasar/${PRODUCT_PROTECT_SUPER_6_PATH}/:code`,
    exact: true,
    component: JourneyRenew,
  },
  {
    path: `/pasar/${PRODUCT_TAKAFUL_USAHAWAN_3_IN_1_PATH}/:code`,
    exact: true,
    component: JourneyRenew,
  },
  {
    path: `/pasar/${PRODUCT_SME_OWNERPROTECT_PATH}/:code`,
    exact: true,
    component: JourneyRenew,
  },
  {
    path: `/pasar/${PRODUCT_BILLPROTECT_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/pasar/${PRODUCT_CARDPROTECT_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/pasar/${PRODUCT_PROTECT_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/pasar/${PRODUCT_PROTECT_SUPER_6_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/pasar/${PRODUCT_TAKAFUL_USAHAWAN_3_IN_1_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/pasar/${PRODUCT_SME_OWNERPROTECT_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/pasar/${PRODUCT_PROTECT_BOOST_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/pasar/${PRODUCT_CANCER_CARE_PRIME_PATH}`,
    exact: true,
    component: JourneyCancerCarePrime,
  },
  {
    path: `/pasar/${PRODUCT_CANCER_CARE_PRIME_PATH}/:code`,
    exact: true,
    component: JourneyCancerCarePrime,
  },
  {
    path: `/epenjana/${PRODUCT_TRAVEL_DOMESTIC_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/epenjana/${PRODUCT_HOSPITAL_CASH_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/epenjana/${PRODUCT_BILLPROTECT_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/epenjana/${PRODUCT_CARDPROTECT_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/epenjana/${PRODUCT_PROTECT_SUPER_6_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/epenjana/${PRODUCT_TAKAFUL_USAHAWAN_3_IN_1_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/epenjana/${PRODUCT_SME_OWNERPROTECT_PATH}`,
    exact: true,
    component: Journey,
  },
  {
    path: `/${PRODUCT_CELCOM_CPROTECT_PATH}/:code`,
    exact: true,
    component: CProtect,
  },
  {
    path: `/${PRODUCT_CELCOM_CPROTECT_RX_PATH}/:code`,
    exact: true,
    component: CProtect,
  },
];

export default routes;
