import styled, { css } from "styled-components";
import { COLORS } from "../../../constants/variables";

const topHeaderSize = 40;
export const headerHeight = 100;

const primaryRed = "#ea0029";

export const HeaderWrapper = styled("header")`
  height: ${headerHeight}px;
  background-color: ${COLORS.primaryRed};
  border-bottom: 1px solid #d1dee5;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
  &.hide {
    transform: translateY(-${topHeaderSize}px);
  }

  .extra-header {
    background-color: #FFF;
    height: 40px;
    text-transform: uppercase;
    color: #336c8a;
    display: flex;
    align-items: center;
    font-size: 10px;
    padding: 0 20px;
    img {
      margin: 0 5px;
      width: 60px;
    }
    .left-header {
      display: flex;
      align-items: center;
      @media screen and (max-width: 900px) {
        margin: auto;
      }
      > img {
        margin-top: -2px;
      }
    }
    .right-header {
      display: flex;
      align-items: center;
      margin-left: auto;
      @media screen and (max-width: 900px) {
        display: none;
      }
      > .logo-wrapper {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: -6px;
        //&:before {
        //  content: "";
        //  position: absolute;
        //  bottom: 11px;
        //  background-color: red;
        //  height: 1px;
        //  right: 0;
        //  left: 0;
        //  width: 100%;
        //}
        &:first-child {
          margin-left: 10px;
        }
        > img {
          margin-left: 0;
          height: 40px;
          &:not(:last-child) {
            margin-right: 8px;
          }
          &.axiata {
            //margin-top: -2px;
          }
          &.celcom {
            margin-top: -4px;
            width: 65px;
            height: initial;
          }
          &.boost {
            //margin-top: -2px;
          }
          &.ge {
            height: auto;
          }
        }
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    height: calc(100% - ${topHeaderSize}px);
    position: relative;
    z-index: 200;
    background-color: ${COLORS.primaryRed};

    &.show-dropdown{
      @media screen and (max-width: 900px){
        background-color: #FFF;
      }
    }

    .boost-logo{
      &>img{
        width: 150px;
      }
    }
  }

  .menu-toggle-button {
    width: 40px;
    height: 40px;
    background: transparent;
    padding: 0;
    border: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    outline: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%) scale(0.6);

    @media screen and (min-width: 900px) {
      display: none;
    }

    &.hideNav {
      display: none;
    }

    &.active {
      span {
        background: ${COLORS.primaryRed};

        &:nth-child(1) {
          transform: translate(8px, 0px) rotate(45deg);
        }
        &:nth-child(2) {
          transform: translateX(100%);
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translate(7px, 0) rotate(-45deg);
        }
      }
    }

    span {
      //position: absolute;
      width: 40px;
      height: 4px;
      border-radius: 4px;
      background: #FFF;
      display: block;
      transform-origin: 0 0;
      transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      &:nth-child(2) {
        margin: 10px 0;
        transform-origin: 0 100%;
      }
    }
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    // margin: auto;
    @media screen and (max-width: 900px) {
      margin: auto;
    }
  }

  .logo {
    @media screen and (max-width: 900px) {
      margin: auto;
    }
  }

  .header-nav {
    display: flex;
    align-items: center;
    margin-left: 50px;
    flex-grow: 1;
    height: 100%;
    @media screen and (max-width: 900px) {
      position: fixed;
      left: 0;
      top: ${headerHeight}px;
      height: 0;
      width: 100%;
      background-color: ${COLORS.primaryRed};
      display: block;
      z-index: 100;
      margin-left: 0;
      overflow: auto;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-10px);
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
      padding-bottom: 40px;
    }
    &.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(-1px);
      @media screen and (max-width: 900px){
        height: ${(props) => (props.showExtraHeader
    ? window.innerHeight - headerHeight
    : window.innerHeight - headerHeight + topHeaderSize)}px;
      }
    }
    &.hideNav {
      display: none;
    }
    .header-nav-btn {
      text-transform: uppercase;
      color: #FFF;
      /* font-weight: bold; */
      text-decoration: none;
      font-size: 0.75rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 0 25px;
      @media screen and (max-width: 900px) {
        min-height: 56px;
        line-height: 56px;
        height: auto;
        display: block;
        padding: 0;
      }
      > svg {
        margin-left: 4px;
        transform: rotate(0deg);
        transition: transform 0.2s linear;

        &.up{
          transform: rotate(-180deg);
          transition: transform 0.2s linear;
        }
      }
      &.active {
        font-weight: 900;
      }
      .header-nav-mobile-toggle {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 56px;
        @media screen and (min-width: 900px) {
          display: none;
        }
      }
      @media not all and (hover: none) {
        &:hover {
          font-weight: bold;
          background-color: rgba(255,255,255,0.5);

          .nav-dropdown {
            opacity: 1;
            transform: translateY(0);
            visibility: visible;
            > .nav-dropdown-item {
              transform: translateX(0);
              opacity: 1;
            }
          }
        }
      }

      &:not(:last-child) {
        //margin-right: 50px;
      }

      &.solid {
        background-color: #102938;
        border-radius: 4px;
        color: #fff;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.29);
        height: 36px;
        padding: 0 20px;
        box-sizing: border-box;
        transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
        @media screen and (max-width: 900px) {
          width: 120px;
          line-height: 36px;
          min-height: initial;
        }
        @media not all and (hover: none) {
          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.29);
          }
        }
      }

      &.yellow {
        background-color: #ea0029;
      }

      .nav-dropdown {
        position: absolute;
        top: ${headerHeight - topHeaderSize}px;
        left: 0;
        background-color: #f2f5f7;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
        transform: translateY(-10px);
        opacity: 0;
        visibility: hidden;
        transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
        @media screen and (max-width: 900px) {
          top: 0;
          left: 0;
          width: 100%;
          position: relative;
          visibility: visible;
          transform: translateY(0);
          box-shadow: initial;
          //opacity: 0;
          //max-height: 0;
          background-color: #FFF;

          /**
          Thinking of better way to solve this
           */
          display: none;
        }

        &.show {
          opacity: 1;
          //max-height: 800px;
          display: block;
        }

        .nav-dropdown-item {
          height: 40px;
          white-space: nowrap;
          padding: 0 25px;
          color: #B8362F;
          background-color: rgba(240, 61, 61, 0.1);
          text-decoration: none;
          display: flex;
          align-items: center;
          text-align: left;
          position: relative;
          transform: translateX(-10px);
          opacity: 0;
          transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1),
            opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
          @media screen and (max-width: 900px) {
            display: block;
            height: auto;
            min-height: 56px;
            line-height: 56px;
            padding: 0;
            text-align: center;
            opacity: 1;
            transform: translateX(0);
          }
          ${() => new Array(10).fill().map(
    (item, index) => css`
                &:nth-child(${index + 1}) {
                  transition-delay: ${(index + 1) * 50}ms;
                }
              `
  )}
          > svg {
            margin-left: 4px;
            transform: rotate(0deg);
            transition: transform 0.2s linear;

            &.up{
              transform: rotate(-180deg);
              transition: transform 0.2s linear;
            }
          }
          @media not all and (hover: none) {
            &:hover {
              background-color: rgba(240,61,61,0.3);

              .nav-second-dropdown {
                opacity: 1;
                transform: translateX(0);
                visibility: visible;
                > .nav-dropdown-item {
                  transform: translateX(0);
                  opacity: 1;
                }
              }
            }
          }

          .nav-second-dropdown {
            position: absolute;
            top: 0;
            left: 100%;
            background-color: #f2f5f7;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
            transform-origin: top;
            transform: translateX(-10px);
            opacity: 0;
            visibility: hidden;
            transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
            @media screen and (max-width: 900px) {
              top: 0;
              left: 0;
              width: 100%;
              position: relative;
              visibility: visible;
              transform: translateX(0);
              box-shadow: initial;
              //max-height: 0;
              background-color: #FFF;

              /**
                Thinking of better way to solve this
              */
              display: none;

              &>.nav-dropdown-item{
                background-color: #FFF;
              }

              * + *{
                border-top: solid 1px #DCDCDC;
              }
            }

            &.show {
              display: block;
              opacity: 1;
              //max-height: 700px;
            }
          }
        }
      }
    }

    .header-action {
      display: flex;
      align-items: center;
      margin-left: auto;
      height: 100%;
      @media screen and (max-width: 900px) {
        height: auto;
        display: block;
      }
      .header-nav-btn {
        &:last-child {
          margin-left: 10px;
          @media screen and (max-width: 900px) {
            margin: 0 auto;
          }
        }
      }
    }
  }
`;

export const HeaderEmptyPlaceholder = styled("div")`
  height: ${(props) => (props.showAds ? headerHeight + props.adsCount * 40 : headerHeight)}px;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
`;
