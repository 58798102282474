import * as React from "react";
import MiddlePopup from "../MiddlePopup";
import SidePopup from "../SidePopup";
import axios from "axios";
import { BASE_URL, checkPopupVisible } from "../Util";
import GlobalContext from "../../../GlobalContext";

class PopupArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationBanners: []
    };
  }

  callNotificationBannerAPI = () => {
    try {
      axios.get(BASE_URL + "/api/notificationBanners").then(response => {
        const {
          data: {
            response: { notificationBanners }
          }
        } = response;

        this.setState({
          notificationBanners: notificationBanners
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount() {
    this.callNotificationBannerAPI();
  }

  render() {
    const { notificationBanners } = this.state;
    return (
      <GlobalContext.Consumer>
        {globalContext => {
          // console.log(globalContext.currentActive);
          return (
            <>
              {notificationBanners &&
                notificationBanners.map((item, index) => {
                  const {
                    hide_on_slugs,
                    show_on_slugs,
                    show_position,
                    show_on_all_pages,
                    name
                  } = item;

                  const showPopup = checkPopupVisible({
                    hide_on_slugs,
                    show_on_slugs,
                    show_on_all_pages,
                    currentActive: globalContext.currentActive
                  });
                  // console.log(item, showPopup)
                  if (!showPopup) return null;

                  if (show_position === "top") {
                    return null;
                  } else if (show_position === "middle") {
                    const {
                      image_arr,
                      image_mobile,
                      show_position,
                      url,
                      url_open_new_tab,
                      show_type,
                      show_type_option
                    } = item;
                    const image = image_arr.length !== 0 && image_arr[0];
                    const imageMobile = image_mobile
                      ? image_mobile.length !== 0 && image_mobile[0]
                      : image;
                    /**
                     * Parse in title hack is force to rerender,
                     * will migrate to global state
                     */

                    return (
                      <MiddlePopup
                        key={`${index}-${name}-${globalContext.currentActive}`}
                        title={globalContext.currentActive}
                        img={image}
                        imageMobile={imageMobile}
                        position={show_position}
                        url={url}
                        newTab={url_open_new_tab === 1}
                        showType={show_type}
                        showTypeOption={show_type_option}
                      />
                    );
                  } else {
                    const {
                      image_arr,
                      image_mobile,
                      show_position,
                      url,
                      url_open_new_tab,
                      show_type,
                      show_type_option
                    } = item;

                    const image = image_arr.length !== 0 && image_arr[0];
                    const imageMobile = image_mobile
                      ? image_mobile.length !== 0 && image_mobile[0]
                      : image;

                    return (
                      <SidePopup
                        img={image}
                        imageMobile={imageMobile}
                        position={show_position}
                        url={url}
                        newTab={url_open_new_tab === 1}
                        showType={show_type}
                        showTypeOption={show_type_option}
                      />
                    );
                  }
                })}
            </>
          );
        }}
      </GlobalContext.Consumer>
    );
  }
}

export default PopupArea;
