import axios from "axios";

const API_URL = process.env.REACT_APP_FORM_API;
const PAYMENT_API_URL = process.env.REACT_APP_PAYMENT_API;
const { REACT_APP_CURLEC_API } = process.env;

export default {
  post: function (urlParam, payload) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}${urlParam}`, payload, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  put: function (urlParam, payload) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`${API_URL}${urlParam}`, payload, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  get: function (urlParam) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`${API_URL}${urlParam}`, {
          withCredentials: true,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  postToPayment: function (urlParam, payload) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`${PAYMENT_API_URL}${urlParam}`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  postToCurlec: function (urlParam, payload) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`${REACT_APP_CURLEC_API}${urlParam}`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  postWithHost: function (host, urlParam, payload) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`${host}${urlParam}`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
};
