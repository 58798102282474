import * as React from "react";
import { FaTimes } from "react-icons/fa";
import { SidePopupWrapper } from "./Styled";
// import { Link } from "react-router-dom";
import Link from '../../Public/Util/Link'

const initialState = {
  animate: true,
  show: true,
  scrollShow: true
};

class SidePopup extends React.Component {
  callbackTimeout;
  autoCloseTimeout;
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  closePopup = () => {
    const { callback } = this.props;
    const that = this;
    this.setState({
      animate: false
    });
    this.callbackTimeout = setTimeout(function() {
      // callback();
      that.setState({
        show: false
      });
    }, 400);
  };

  handleScroll = () => {
    this.setState({
      scrollShow: true
    });
    window.removeEventListener("scroll", this.handleScroll);
  };

  componentDidMount() {
    const { showType, showTypeOption } = this.props;
    const that = this;
    if (showType === "on-time") {
      const countdown = Number(showTypeOption);
      console.log(countdown);
      this.autoCloseTimeout = setTimeout(function() {
        that.closePopup();
      }, countdown * 1000);
    } else if (showType === "on-scroll") {
      this.setState({
        scrollShow: false
      });
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  componentWillUnmount() {
    const { showType } = this.props;
    if (showType === "on-scroll") {
      window.removeEventListener("scroll", this.handleScroll);
    }
    clearTimeout(this.callbackTimeout);
    clearTimeout(this.autoCloseTimeout);
    this.setState(initialState);
  }

  render() {
    const { img, imageMobile, position, url, newTab, showType } = this.props;
    const { animate, show, scrollShow } = this.state;
    const isExternalLink = url && url.startsWith("http");

    return (
      show &&
      scrollShow && (
        <SidePopupWrapper
          className={`${position} ${animate ? "" : "fading"} ${
            showType === "on-entry" ? "fade-in" : ""
          }`}
          background={img}
          backgroundMobile={imageMobile}
        >
          {url != null ? isExternalLink ? (
            <a href={url} target={newTab ? "_blank" : ""} />
          ) : (
            <Link to={url} target={newTab ? "_blank" : ""} />
          )
          : null}

          <button className="close-ads" onClick={this.closePopup}>
            <FaTimes />
          </button>
        </SidePopupWrapper>
      )
    );
  }
}
export default SidePopup;
