import Bowser from "bowser";
import publicIp from "public-ip";

const nav = window.navigator;
export const browserInformation = async () => {
  let browserInformation = Bowser.parse(nav.userAgent);
  browserInformation.userAgent = nav.userAgent;
  browserInformation.ipv4 = await publicIp.v4();
  return browserInformation;
};
