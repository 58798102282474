import styled from "styled-components";

export const HeaderAdsWrapper = styled("div")`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background: ${(props) => (props.background ? props.background : "#ccc")};
  z-index: 100;
  > a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-decoration: none;
  }
  .ads-icon {
    width: 30px;
    height: 30px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 10px;
  }
  .ads-text {
    color: ${(props) => (props.color ? props.color : "#333")};
    & + .ads-icon {
      margin-right: 0;
      margin-left: 10px;
    }
  }
  .close-ads {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    border: 0;
    background: none;
    outline: none;
    padding: 0;
    margin-left: auto;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media screen and (max-width: 900px) {
      right: 0;
    }
    > svg {
      font-size: 16px;
      color: ${(props) => (props.color ? props.color : "#333")};
    }
  }
`;
