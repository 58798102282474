// export const BASE_URL = 'http://aspirasiwebdev-env.eba-cujhuj2b.ap-southeast-1.elasticbeanstalk.com'
export const BASE_URL = process.env.REACT_APP_API;
export const BASE_WEB_TITLE = "Boost Credit";

export const getYouTubeId = (url) => {
  return url.match(
    /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^\/&]{10,12})/
  )[1];
};

export const checkPopupVisible = (props) => {
  const {
    hide_on_slugs,
    show_on_slugs,
    show_on_all_pages,
    currentActive,
  } = props;
  let showPopup = true;

  const currentPath = window.location.pathname;

  let currentPage = currentActive;

  if (currentActive === "pasar_inner") {
    currentPage = "pasar/";
  }

  const hideArr = hide_on_slugs && hide_on_slugs.split(",");
  const hideOnCurrentPage = hide_on_slugs
    && hideArr.find((item) => {
      /**
       * Global wildcard check for subpages first,
       * if not found, proceed with subpages
       */
      if (item === "/pasar/*") {
        return currentPath.includes(currentPage);
      } if (currentPage === "pasar" || currentActive === "pasar_inner") {
        /**
         * Due to /pasar, /pasar/* and /pasar/SUB_PAGE share the same start index,
         * strict check on each path
         */
        return item === currentPath;
      }
      /**
         * Else do loose check on remaining domain
         */
      return item.includes(currentPage);
    });

  const showArr = show_on_slugs && show_on_slugs.split(",");
  const showOnCurrentPage = show_on_slugs
    && showArr.find((item) => {
      if (item === "/pasar/*") {
        return currentPath.includes(currentPage);
      } if (currentPage === "pasar" || currentActive === "pasar_inner") {
        return item === currentPath;
      }
      return item === currentPage;
    });

  // console.log(show_on_all_pages, showArr, hideArr);

  if (show_on_all_pages === 1) {
    if (hideOnCurrentPage) {
      // return null;
      showPopup = false;
    }
  } else if (!showOnCurrentPage) {
    // return null;
    showPopup = false;
  }

  return showPopup;
};

export const getValueByKey = (arr, key) => {
  if (arr.length === 0) return "";
  return (
    arr
    && arr.length !== 0
    && (arr.find((item) => item.key === key).value
      || arr.find((item) => item.key === key).image_arr)
  );
};

export const sanitizeHTML = (string) => {
  return string.replace(/<[^>]*>?/gm, "");
};

export const corporateGovernanceSheetStatement = "https://www.axiata.com/sites/default/files/docs/ABAC-Policy-Statement.pdf";
export const corporateGovernanceSheet = "https://aspirasi-co-assets.s3-ap-southeast-1.amazonaws.com/documents/ABAC-Policy.pdf";
export const whistleblowingPolicy = "https://www.axiata.com/sites/default/files/docs/Whistleblowing_Policy_Procedures.pdf";
export const whistleblowerReport = "https://axiatagroup.integrityline.com";
