import * as React from "react";
import { useEffect } from "react";
import { HeaderWrapper, HeaderEmptyPlaceholder } from "./Styled";
// // import { Link } from "react-router-dom";
import Link from '../Util/Link'
import { FaCaretDown, FaCaretRight, FaTimes } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import GlobalContext from "../../../GlobalContext";
import HeaderAds from "../HeaderAds";
import SidePopup from "../SidePopup";
import axios from "axios";
import { BASE_URL, checkPopupVisible } from "../Util";
import { useMediaQuery } from "react-responsive";

const isMobile = require("is-mobile");

const LogoWhite = require("../../../images/boost-credit-white.svg");
const LogoRed = require("../../../images/boost-credit-red.svg");
const axiata = require("../../../images/axiata.svg");
const celcom = require("../../../images/celcom.svg");
const boost = require("../../../images/boost.svg");
const ge = require("../../../images/ge-logo.svg");

const externalUrl = [
  {
    name: "Axiata Group",
    url: "https://www.axiata.com",
  },
  {
    name: "Axiata Digital",
    url: "https://www.axiatadigital.com",
  },
  {
    name: "Celcom",
    url: "https://www.celcom.com.my",
  },
  {
    name: "Boost",
    url: "https://www.myboost.com.my",
  },
  {
    name: "Apigate",
    url: "https://www.apigate.com",
  },
  {
    name: "Ada",
    url: "https://ada-asia.com",
  },
  {
    name: "Edotco",
    url: "https://edotcogroup.com",
  },
];

const initialState = {
  showExtraHeader: true,
  showDropdown: false,
  showNav: false,
  showSecondNav: false,
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      ...{ notificationBanners: [], headerAdsCount: 0 },
    };

    // Add event listener
    window.addEventListener('resize', this.isTabletOrMobile)
  }

  handleScroll = (event) => {
    let doc = document.documentElement;
    let scrollTop = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

    const showExtraHeader = scrollTop <= 0;

    if (showExtraHeader) {
      this.setState({
        showExtraHeader: true,
      });
    } else {
      this.setState({
        showExtraHeader: false,
      });
    }
  };

  async componentDidMount() {
    const { history } = this.props;
    history.listen((location, action) => {
      this.setState(initialState);
    });
    await this.callNotificationBannerAPI();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  detectShowHeaderAds = () => {
    const globalContext = this.context;
    const { notificationBanners } = this.state;
    let currentPage = globalContext.currentActive;

    if (globalContext.currentActive === "pasar_inner") {
      currentPage = "/pasar/*";
    }

    const headerAdsCount = notificationBanners.reduce(
      (acc, item) => acc + item.hide_on_slugs.includes(currentPage),
      0
    );

    this.setState({
      headerAdsCount: headerAdsCount,
    });
  };

  componentDidUpdate = async (prevProps) => {

    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Hack: setTimeout delays click until end of current
      // event loop to ensure new screen has mounted.
      // this.detectShowHeaderAds();
      await this.callNotificationBannerAPI();
    }

    // Handling to hide the top nav bar when child products are selected
    if (this.props.location.search !== prevProps.location.search) {
      this.handleScroll(null)
    }
  };

  toggleDropdown = (e) => {
    e.preventDefault();
    this.setState({
      showDropdown: !this.state.showDropdown,
      showNav: false,
      showSecondNav: false,
    });
  };

  toggleNav = (e) => {
    e.preventDefault();
    this.setState({
      showNav: !this.state.showNav,
      showSecondNav: false,
    });
  };

  toggleSecondNav = (e) => {
    e.preventDefault();
    this.setState({
      showSecondNav: !this.state.showSecondNav,
    });
  };

  callNotificationBannerAPI = async () => {
    try {
      await axios
        .get(BASE_URL + "/api/notificationBanners")
        .then((response) => {
          const {
            data: {
              response: { notificationBanners },
            },
          } = response;

          const headerBanners = notificationBanners
            .filter((item) => item["show_position"] === "top")
            .map((item, index) => {
              return {
                ...item,
                ...{
                  id: index,
                  show: true,
                },
              };
            });

          this.setState({
            notificationBanners: headerBanners,
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  hideAds = (id) => {
    console.log(id);
    // 1. Make a shallow copy of the items
    let items = [...this.state.notificationBanners];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...items[id] };
    // 3. Replace the property you're intested in
    item.show = false;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    items[id] = item;
    // 5. Set the state to our new copy
    this.setState({
      notificationBanners: items,
    });
  };

  isTabletOrMobile = () => {
    if(window.innerWidth > 900){
      this.setState({
        showDropdown: false,
      });
    }
  }

  render() {
    const {
      showExtraHeader,
      showDropdown,
      showNav,
      showSecondNav,
      showAds,
      notificationBanners,
      headerAdsCount,
      showHeaderAction,
    } = this.state;


    return (
      <GlobalContext.Consumer>
        {(globalContext) => {
          const {
            show: headerAdsShow,
            icon: headerAdsIcon,
            color: headerAdsColor,
            background: headerAdsBackground,
            text: headerAdsText,
          } = globalContext.headerAds;
          // const headerAdsShow = globalContext.headerAds.show;
          const {
            show: sidePopupShow,
            img: sidePopupImage,
            position: sidePopupPosition,
          } = globalContext.sidePopup;
          return (
            <>
              <HeaderWrapper
                className={`extra-header ${showExtraHeader ? "show" : "hide"}`}
                showExtraHeader={showExtraHeader}
                // secondLayerItemCount={externalUrl.length}
                // secondLayerOpened={showSecondNav}
              >
                <div className="extra-header">
                  <div className="left-header">
                    An <img src={axiata} /> Company
                  </div>
                  <div className="right-header">
                    In partnership with
                    <div className="logo-wrapper">
                      <img className="axiata" src={axiata} />
                      <img className="ge" src={ge} />
                      <img className="boost" src={boost} />
                      <img className="celcom" src={celcom} />
                    </div>
                  </div>
                </div>
                <div className={`wrapper ${showDropdown ? "show-dropdown" : ""}`}>
                  <Link className="logo-wrapper boost-logo" to="/">
                    <img className="logo" src={`${showDropdown ? LogoRed : LogoWhite}`} />
                  </Link>
                  <nav
                    className={`header-nav ${showDropdown ? "show" : ""} ${
                      this.props.hideNav ? "hideNav" : ""
                    }`}
                  >
                    {globalContext.headerAction === "" && (
                      <>
                        <Link
                          className={`header-nav-btn ${
                            globalContext.currentActive === "home"
                              ? "active"
                              : ""
                          }`}
                          to="/"
                        >
                          Home
                        </Link>
                        <Link
                          className={`header-nav-btn ${
                            globalContext.currentActive === "media"
                              ? "active"
                              : ""
                          }`}
                          to="/media"
                        >
                          Media
                        </Link>
                        <span
                          className={`header-nav-btn ${
                            globalContext.currentActive === "about"
                              ? "active"
                              : ""
                          }`}
                        >
                          <a
                            className="header-nav-mobile-toggle"
                            href="#!"
                            onClick={this.toggleNav}
                          />
                          About <FaCaretDown className={`${showNav ? "up" : ""}`}/>
                          <div
                            className={`nav-dropdown ${showNav ? "show" : ""}`}
                          >
                            <Link className="nav-dropdown-item" to="/about">
                              Who we are
                            </Link>
                            <span className="nav-dropdown-item">
                              <a
                                className="header-nav-mobile-toggle"
                                href="#!"
                                onClick={this.toggleSecondNav}
                              />
                              Our axiata family <FaCaretDown className={`${showSecondNav ? "up" : ""}`}/>
                              <div
                                className={`nav-second-dropdown ${
                                  showSecondNav ? "show" : ""
                                }`}
                              >
                                {externalUrl.map((item) => {
                                  return (
                                    <a
                                      target="_blank"
                                      key={`${item.url}`}
                                      className="nav-dropdown-item"
                                      href={`${item.url}`}
                                    >
                                      {item.name}
                                    </a>
                                  );
                                })}
                              </div>
                            </span>
                          </div>
                        </span>
                        <Link
                          className={`header-nav-btn ${
                            globalContext.currentActive === "contact"
                              ? "active"
                              : ""
                          }`}
                          to="/contact-us"
                        >
                          Contact Us
                        </Link>
                      </>
                    )}
                    <div className="header-action">
                      {globalContext.headerAction === "" && (
                        <Link
                          className={`header-nav-btn ${
                            globalContext.currentActive === "assist"
                              ? "active"
                              : ""
                          }`}
                          to="/assist"
                        >
                          Aspirasi Assist
                        </Link>
                      )}
                      {globalContext.headerAction !== "" ? (
                        <a
                          className="header-nav-btn solid yellow"
                          href={globalContext.headerAction}
                        >
                          Apply Now
                        </a>
                      ) : (
                        <Link className="header-nav-btn solid" to="/pasar">
                          Go to pasar
                        </Link>
                      )}
                    </div>
                  </nav>
                  <button
                    className={`menu-toggle-button ${
                      showDropdown ? "active" : ""
                    } ${this.props.hideNav ? "hideNav" : ""}`}
                    onClick={this.toggleDropdown}
                  >
                    <span />
                    <span />
                    <span />
                  </button>
                </div>

                {notificationBanners.length !== 0 &&
                  notificationBanners.map((item, index) => {
                    const {
                      hide_on_slugs,
                      show_on_slugs,
                      show_on_all_pages,
                      show,
                    } = item;

                    const showPopup = checkPopupVisible({
                      hide_on_slugs,
                      show_on_slugs,
                      show_on_all_pages,
                      currentActive: globalContext.currentActive,
                    });

                    if (!showPopup || !show) {
                      return null;
                    } else {
                      const {
                        id,
                        title,
                        icon_arr,
                        icon_position,
                        title_color_hex,
                        bg_color_hex,
                        url,
                        url_open_new_tab,
                      } = item;

                      const icon = icon_arr.length !== 0 && icon_arr[0];

                      return (
                        <HeaderAds
                          color={title_color_hex}
                          background={bg_color_hex}
                          text={title}
                          icon={icon}
                          iconPosition={icon_position}
                          url={url}
                          newTab={url_open_new_tab === 1}
                          callback={() => this.hideAds(id)}
                        />
                      );
                    }
                  })}
              </HeaderWrapper>
              <HeaderEmptyPlaceholder
                key={globalContext.currentActive}
                className="header-empty-placeholder"
                showAds={
                  notificationBanners.reduce((acc, item) => {
                    const {
                      hide_on_slugs,
                      show_on_slugs,
                      show_on_all_pages,
                      show,
                    } = item;

                    const showPopup = checkPopupVisible({
                      hide_on_slugs,
                      show_on_slugs,
                      show_on_all_pages,
                      currentActive: globalContext.currentActive,
                    });

                    if (!showPopup || !show) {
                      return acc;
                    } else {
                      return acc + item.show;
                    }
                  }, 0) !== 0
                }
                adsCount={notificationBanners.reduce((acc, item) => {
                  const {
                    hide_on_slugs,
                    show_on_slugs,
                    show_on_all_pages,
                    show,
                  } = item;

                  const showPopup = checkPopupVisible({
                    hide_on_slugs,
                    show_on_slugs,
                    show_on_all_pages,
                    currentActive: globalContext.currentActive,
                  });

                  if (!showPopup || !show) {
                    return acc;
                  } else {
                    return acc + item.show;
                  }
                }, 0)}
              />
            </>
          );
        }}
      </GlobalContext.Consumer>
    );
  }
}

Header.contextType = GlobalContext;

export default withRouter(Header);
