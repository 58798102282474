import {
  osVersion,
  osName,
  browserVersion,
  browserName,
  engineName,
  engineVersion,
  getUA
} from "react-device-detect";

import { post } from "axios";
import { browserInformation } from "./browserInformation";

const nav = window.navigator;

export const deviceInfo = async (name, subName, sessionKey) => {

  // let browser = await browserInformation()
  // if (browser) {
  // let ipAddress = browser.ipv4 || ''
  // let versionName = browser.os.versionName || ''

  // let param = {
  //     journey: {
  //         name,
  //         subName,
  //         languageCode:name == 'KONGSI' ?'BM':'EN'
  //     },
  //     transaction: {
  //         type:'SESSION_KEY',
  //         id:sessionKey
  //     },
  //     network: {
  //         ipAddress: ipAddress
  //     },
  //     browser: {
  //         browserName,
  //         browserVersion,
  //         engineName,
  //         engineVersion,
  //         platformType: nav.platform,
  //         platformVendor: nav.vendor,
  //         getUA,
  //         languageCode:nav.language
  //     },
  //     os: {
  //         name: osName,
  //         version: osVersion,
  //         versionName
  //     },
  //     screen : {
  //         width: window.screen.width,
  //         height: window.screen.height,
  //         unit:'PX'
  //     }
  // }
  // if (process.env.REACT_APP_ENV !== "production") console.log(param)
  // post('https://stage.aspirasium.com/analytics/data/device', param)
  // }
};
