import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga";
import Header from "./components/Public/Header";
import Footer from "./components/Public/Footer";
import "./global.css";
import "flickity/dist/flickity.min.css";
import { SuspensePageLoader } from "./components/Pages/Journey/components/PageLoader";
import PopupArea from "./components/Public/PopupArea";
import routes from "./components/Pages/Journey/router";
import StoreProvider from "./components/Pages/Journey/store/context";
import { PRODUCT_CELCOM_CPROTECT_PATH, PRODUCT_CELCOM_CPROTECT_RX_PATH } from "./components/Pages/Journey/utils";
/**
 * Pages
 */
const Home = React.lazy(() => import("./components/Pages/Home"));
const ContactUs = React.lazy(() => import("./components/Pages/ContactUs"));
const About = React.lazy(() => import("./components/Pages/About"));
const Media = React.lazy(() => import("./components/Pages/Media"));
const MediaList = React.lazy(() => import("./components/Pages/Media/Listing"));
const MediaView = React.lazy(() => import("./components/Pages/Media/View"));
const Assist = React.lazy(() => import("./components/Pages/Assist"));
const Pasar = React.lazy(() => import("./components/Pages/Pasar"));
const GEH = React.lazy(() => import("./components/Pages/Pasar/GEH"));
const PasarView = React.lazy(() => import("./components/Pages/Pasar/View"));
const EPenjana = React.lazy(() => import("./components/Pages/EPenjana"));
const IndonesiaMicrosite = React.lazy(() => import("./components/Pages/IndonesiaMicrosite"));
const IndonesiaMicrositeProductView = React.lazy(() => import("./components/Pages/IndonesiaMicrosite/Product/View"));
const BerizinDanDiawasiPerhatian = React.lazy(() => import("./components/Pages/IndonesiaMicrosite/BerizinDanDiawasiPerhatian/View"));
const IndonesiaMicrositeAbout = React.lazy(() => import("./components/Pages/IndonesiaMicrosite/About"));
const KegiatanSebelumbya = React.lazy(() => import("./components/Pages/IndonesiaMicrosite/KegiatanSebelumnya/View"));
const KongsiKlub = React.lazy(() => import("./components/Pages/KongsiKlub"));
const KongsiGetContact = React.lazy(() => import("./components/Pages/KongsiGetContact"));
const JourneyMotor = React.lazy(() => import("./components/Pages/JourneyMotor"));
const JourneyStock = React.lazy(() => import("./components/Pages/JourneyStock"));
const JourneyRed = React.lazy(() => import("./components/Pages/JourneyRed"));

ReactGA.initialize(process.env.REACT_APP_GA_ID, {
  debug: process.env.REACT_APP_ENV === "development",
});

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

const renderComponents = routes.map((route, index) => (
  <Route
    path={route.path}
    exact={route.exact}
    productType={route.productType}
    component={route.component}
    key={index}
  />
));

class App extends React.Component {
  isFormPage() {
    const url = window.location.pathname.split("/");
    return url[3] === "apply" || url[3] === "tracker" || url[3] === "tracker-boost" || url[1] === PRODUCT_CELCOM_CPROTECT_PATH || url[1] === PRODUCT_CELCOM_CPROTECT_RX_PATH;
  }

  isEPenjana() {
    const url = window.location.pathname.split("/");
    return url[1] === "epenjana";
  }

  isIndonesia() {
    const url = window.location.pathname.split("/");
    return url[1] === "id";
  }

  isKongsiKlub() {
    const url = window.location.pathname.split("/");
    return url[1] === "kongsiklub";
  }

  render(props) {
    return (
      <div className="App">
        {!this.isFormPage() && !this.isIndonesia() && !this.isKongsiKlub() && (
          <Header hideNav={this.isEPenjana()} />
        )}
        <React.Suspense fallback={null}>
          <Switch>
            {/* <Route exact path="/" component={Home} /> */}
            <Route
              exact
              path="/"
              component={() => {
                window.location.href =
                  "https://myboost.co/business/capital-by-boost-credit";
                return null;
              }}
            />

            <Route exact path="/media" component={Media} />
            <Route
              exact
              path="/allmedia/:mediaType/:page?"
              component={MediaList}
            />
            <Route path="/media/:slug" component={MediaView} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/about" component={About} />
            <Route exact path="/assist" component={Assist} />
            <Route exact path="/pasar" component={Pasar} />
            <Route exact path="/learnmore/ge" component={GEH} />
            <Route exact path="/pasar/:slug" component={PasarView} />
            <Route exact path="/epenjana" component={EPenjana} />
            <Route exact path="/epenjana/:slug" component={PasarView} />
            <Route exact path="/kongsiklub" component={KongsiKlub} />
            <Route exact path="/kongsiklub/signup" component={KongsiKlub} />
            <Route exact path="/kongsiklub/get-contact" component={KongsiGetContact} />
            <Route exact path="/id" component={IndonesiaMicrosite} />
            <Route exact path="/id/berizin-dan-diawasi-perhatian" component={BerizinDanDiawasiPerhatian} />
            <Route exact path="/id/tentang-kami" component={IndonesiaMicrositeAbout} />
            <Route exact path="/id/kegiatan-sebelumnya" component={KegiatanSebelumbya} />
            <Route exact path="/pasar/carprotect/apply" component={JourneyMotor} />
            <Route exact path="/pasar/carprotect/apply/private" component={JourneyMotor} />
            <Route exact path="/pasar/carprotect/tracker/:code" component={JourneyMotor} />
            <Route exact path="/pasar/carprotect/tracker-boost/:code" component={JourneyMotor} />
            <Route exact path="/pasar/stockprotect/apply" component={JourneyStock} />
            <Route exact path="/pasar/foodstallprotect/apply" component={JourneyStock} />
            <Route exact path="/pasar/co-vaxprotect/apply" component={JourneyRed} />
            <Route
              exact
              path="/id/pasar/:slug"
              component={IndonesiaMicrositeProductView}
            />
            <Route
              exact
              path="/pasar/view/:slug"
              render={(props) => (
                <Redirect
                  to={`/pasar/${props.match.params.slug}${window.location.search ? window.location.search : ""
                  }`}
                />
              )}
            />
            <StoreProvider>
              <Suspense fallback={<SuspensePageLoader />}>
                {renderComponents}
              </Suspense>
            </StoreProvider>
          </Switch>
        </React.Suspense>
        {!this.isFormPage()
          && !this.isEPenjana()
          && !this.isIndonesia()
          && !this.isKongsiKlub()
          && <Footer />}

        {!this.isFormPage()
          && <PopupArea />
        }
      </div>
    );
  }
}

export default App;
