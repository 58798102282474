export const COLORS = {
  primaryRed: "#ea0029",
  primaryBlack: "#231F20",
  primaryGray: "#8A8A8C",
  primaryBlue: "#102938",

  secondaryGray: "#CDCDCD",

  backgroundGray: "#F9F8F8"
};
