const initialState = {
  isLoading: false,
  step: 0,
  dataForm: null,
  // dataPremium: null,
  dataPremium: {
    tripType: "T",
  },
  // payloadPremium: null,
  payloadPremium: {
    totalPremiumAfterGST: "100.00"
  },
  payloadSaveApplication: null,
  payloadEGHLparams: null,
  payloadPaymentStatus: null,
  isPaymentDialogShow: false,
  dialog: {
    dialogTitle: "",
    dialogContent: "",
    returnHome: false,
  },
  isDialogShow: false,
  insuredPassengerErrors: [{
    error: null
  }],
};

export default initialState;
