import styled from "styled-components";
import { COLORS } from "../../../constants/variables";

export const FooterWrapper = styled("footer")`
  //height: 340px;
  border-top: 1px solid #CCDAE2;
  padding-top: 80px;
  background-color: ${COLORS.primaryBlack};
  .wrapper {
    display: flex;
    &.bottom-footer {
      height: 100%;
      align-items: center;
    }
    @media screen and (max-width: 900px) {
      flex-flow: row wrap;
    }
  }
  .footer-item {
    text-align: left;
    font-family: "Ubuntu", sans-serif;
    @media screen and (max-width: 900px) {
      margin-bottom: 40px;
      flex-basis: 50%;
    }
    &:not(:last-child) {
      margin-right: auto;
      @media screen and (max-width: 900px) {
        margin-right: 0;
      }
    }
    &:last-child {
      @media screen and (max-width: 900px) {
        flex-basis: 100%;
      }
    }
    .footer-title {
      font-size: 0.875rem;
      color: ${COLORS.secondaryGray};
      text-transform: uppercase;
    }
    .footer-item-link {
      display: block;
      text-decoration: none;
      color: #FFF;
      font-size: 0.875rem;
      line-height: 20px;
      > svg {
        margin-right: 8px;
      }
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
    .input-wrapper {
      display: flex;
      align-items: center;
      height: 40px;
      border: 1px solid #FFF;
      border-radius: 8px;
      overflow: hidden;
      .input {
        flex-grow: 1;
        height: 100%;
      }
      input {
        background-color: transparent;
        outline: none;
        color: #FFF;
        border: 0;
        height: 100%;
        width: 100%;
        padding: 0 20px;
        font-size: 0.875rem;
        &::placeholder {
          color: #FFF;
        }
      }
      button {
        background-color: ${COLORS.primaryRed};
        width: 100px;
        height: 100%;
        border: 0;
        font-size: 0.875rem;
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
        outline: none;
      }
    }
  }

  .bottom-footer-wrapper {
    margin-top: 60px;
    min-height: 50px;
    border-top: 1px solid #CCDAE2;
    .wrapper {
      min-height: 50px;
      @media screen and (max-width: 900px) {
        display: block;
        padding-bottom: 20px;
      }
    }
    .bottom-footer-item-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      @media screen and (max-width: 900px) {
        margin: 20px 0;
        flex-flow: row wrap;
      }
    }
  }
  .bottom-footer-item {
    font-family: "Ubuntu", sans-serif;
    text-decoration: none;
    color: ${COLORS.secondaryGray};
    font-size: 0.875rem;
    @media screen and (max-width: 900px) {
      display: block;
      margin: 5px auto;
    }
    &:not(:last-child) {
      margin-right: auto;
    }

    &.terms-privacy{
      display: flex;
      justify-content: space-between;
      margin: 5px 0;

      &>a{
        color: ${COLORS.secondaryGray};
        text-decoration: none;
      }
    }

    .bottom-footer-item-link {
      margin-left: 10px;
      text-decoration: none;
      color: #FFF;
      font-size: 0.875rem;
      line-height: 20px;
    }
  }
`;
