import * as React from "react";
import { FaTimes } from "react-icons/fa";
import { HeaderAdsWrapper } from "./Styled";
// import { Link } from "react-router-dom";
import Link from "../Util/Link";

class HeaderAds extends React.Component {
  render() {
    const {
      icon,
      iconPosition,
      text,
      color,
      background,
      url,
      newTab,
      callback
    } = this.props;
    const isExternalLink = url && url.startsWith("http");
    return (
      <HeaderAdsWrapper color={color} background={background}>
        {url != null ? isExternalLink ? (
          <a href={url} target={newTab ? "_blank" : ""} rel="noreferrer" />
        ) : (
          <Link to={url} target={newTab ? "_blank" : ""} />
        )
          : null}
        {icon && iconPosition === "left" && (
          <div
            className="ads-icon"
            style={{ backgroundImage: `url(${icon})` }}
          />
        )}
        <div className="ads-text" dangerouslySetInnerHTML={{ __html: text }} />
        {icon && iconPosition === "right" && (
          <div
            className="ads-icon"
            style={{ backgroundImage: `url(${icon})` }}
          />
        )}
        <button className="close-ads" onClick={callback}>
          <FaTimes />
        </button>
      </HeaderAdsWrapper>
    );
  }
}
export default HeaderAds;
