import moment from "moment";

export const PRODUCT_TRAVEL_INTERNATIONAL_PATH = "travelworldwide/apply";
export const PRODUCT_TRAVEL_DOMESTIC_PATH = "travellocal/apply";
export const PRODUCT_PROTECT_PATH = "protect/apply";
export const PRODUCT_HOSPITAL_CASH_PATH = "hospicash/apply";
export const PRODUCT_BILLPROTECT_PATH = "billprotect/apply";
export const PRODUCT_CARDPROTECT_PATH = "cardprotect/apply";
export const PRODUCT_PROTECT_SUPER_6_PATH = "protectsuper6/apply";
export const PRODUCT_TAKAFUL_USAHAWAN_3_IN_1_PATH = "takafulusahawan3in1/apply";
export const PRODUCT_SME_OWNERPROTECT_PATH = "smeownerprotect/apply";
export const PRODUCT_PROTECT_BOOST_PATH = "protectboost/apply";
export const PRODUCT_SREEN_PROTECT_PATH = "screenprotect/apply";
export const PRODUCT_CANCER_CARE_PRIME_PATH = "cancercareprime/apply";
export const PRODUCT_CELCOM_CPROTECT_PATH = "celcom-cprotect";
export const PRODUCT_CELCOM_CPROTECT_RX_PATH = "celcom-cprotect-rx";

export const toCent = (amount) => amount * 100;

export const calcAge = (passengers) => {
  const today = new Date(moment().utc().format("MM-DD-YYYY"));
  passengers.map((passenger) => {
    const bs = passenger.insDateOfBirth.split("/");
    const birthDate = new Date(`${bs[1]}-${bs[0]}-${bs[2]}`);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    passenger.insTravellingAs = (age > 18) ? "Adult" : "Child";
    return passenger;
  });

  return passengers;
};

export const calcNoOfAdultAndChild = (passengers, hasAge) => {
  if (!hasAge) {
    passengers = calcAge(passengers);
  }

  let adult = 0;
  let child = 0;

  passengers.map((passenger) => {
    if (passenger.insTravellingAs === "Adult") adult += 1; else child += 1;
    return passenger;
  });

  const noOfAdultAndChild = {
    adult: adult,
    child: child,
  };
  return noOfAdultAndChild;
};

export const getPassengersErrors = (passengers) => {
  const today = new Date(moment().format("MM-DD-YYYY"));
  const errors = [];

  passengers.map((passenger) => {
    if (passenger.dob !== null) {
      const birthDate = new Date(passenger.dob.format("MM-DD-YYYY"));
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      errors.push({ error: (age >= 2 && age <= 70) ? null : "Only allow ages between 2 and 70" });
    } else {
      errors.push({ error: null });
    }
    return passenger;
  });

  return errors;
};

export const getProductTypeByUrl = (location) => {
  // const path = location.pathname.replace(/\//g, "");
  // const path = location.pathname.replace(/^\/?|\/?$/g, "");
  // const path = location.pathname.replace(/\/$/, "");
  const path = location.pathname.replace(/\/pasar\/|\/epenjana\//g, "");
  const splitPath = path.split("/");
  return `${splitPath[0]}/${splitPath[1]}`;
};

export const takafulUsahawan3in1Plan = {
  "starting-out": {
    name: "STARTING OUT",
    plan: "ASOP20",
    price: "RM120.00",
    fees: "RM2.40",
    totalAmountPayable: "RM122.40",
    policy: [
      { name: "Death Benefit", value: "RM20,000" },
      { name: "Total & Permanent Disability Benefit", value: "RM20,000" },
      { name: "Accidental Death Benefit", value: "RM40,000" },
      { name: "Daily Hospitalization Income Allowance Benefit", value: "RM100 daily" },
      { name: "Special Income Benefit", value: "RM1,000" },
      { name: "Contribution Payable", value: "RM120 (6 Months)" },
    ]
  },
  "go-getter": {
    name: "GO-GETTER",
    plan: "ASOP30",
    price: "RM145.00",
    fees: "RM2.90",
    totalAmountPayable: "RM147.90",
    policy: [
      { name: "Death Benefit", value: "RM30,000" },
      { name: "Total & Permanent Disability Benefit", value: "RM30,000" },
      { name: "Accidental Death Benefit", value: "RM60,000" },
      { name: "Daily Hospitalization Income Allowance Benefit", value: "RM100 daily" },
      { name: "Special Income Benefit", value: "RM145 (6 Months)" },
      { name: "Contribution Payable", value: "RM10,000" },
    ]
  }
};

export const smeOwnerProtectPlan = {
  "ASOP15": {
    name: "15 Months",
    plan: "ASOP15",
  },
  "ASOP30": {
    name: "30 Days",
    plan: "ASOP30",
    price: "RM5.30*",
    fees: "",
    totalAmountPayable: "RM5.30",
    policy: [
      { name: "Accidental Death Benefit", value: "RM10,000" },
      { name: "Accidental Permanent Disablement Benefit", value: "RM10,000" },
      { name: "Emergency Expenses Relief Benefit", value: "Up to RM1,000" },
    ]
  },
  "ASOP365": {
    name: "1 Year",
    plan: "ASOP365",
    price: "RM47.70*",
    fees: "RM0.95",
    totalAmountPayable: "RM48.65",
    policy: [
      { name: "Accidental Death Benefit", value: "RM10,000" },
      { name: "Accidental Permanent Disablement Benefit", value: "RM10,000" },
      { name: "Emergency Expenses Relief Benefit", value: "Up to RM1,000" },
    ]
  }
};

export const cardProtectPlan = {
  "starting-out": {
    name: "STARTING OUT",
    plan: "ACP005",
    price: "RM10.00",
    fees: "RM0.50",
    totalAmountPayable: "RM10.50",
    policy: [
      { name: "Death Benefit", value: "RM5,000" },
      { name: "Total and Permanent Disability Benefit", value: "RM5,000" },
      { name: "Accidental Death Beneft", value: "RM10,000" },
    ]
  },
  "go-getter": {
    name: "GO-GETTER",
    plan: "ACP010",
    price: "RM20.00",
    fees: "RM0.50",
    totalAmountPayable: "RM20.50",
    policy: [
      { name: "Death Benefit", value: "RM10,000" },
      { name: "Total and Permanent Disability Benefit", value: "RM10,000" },
      { name: "Accidental Death Beneft", value: "RM20,000" },
    ]
  },
  "rising-star": {
    name: "RISING STAR",
    plan: "ACP015",
    price: "RM30.00",
    fees: "RM0.50",
    totalAmountPayable: "RM30.50",
    policy: [
      { name: "Death Benefit", value: "RM15,000" },
      { name: "Total and Permanent Disability Benefit", value: "RM15,000" },
      { name: "Accidental Death Beneft", value: "RM30,000" },
    ]
  },
  "a-lister": {
    name: "A-LISTER",
    plan: "ACP025",
    price: "RM50.00",
    fees: "RM1.00",
    totalAmountPayable: "RM51.00",
    policy: [
      { name: "Death Benefit", value: "RM25,000" },
      { name: "Total and Permanent Disability Benefit", value: "RM25,000" },
      { name: "Accidental Death Beneft", value: "RM50,000" },
    ]
  },
};

export const cancerCarePrimePlan = {
  "starting-out": {
    name: "Starting Out",
    ages: {
      "18-30": {
        plan: "ACCP101",
        min: 18,
        max: 30,
      },
      "31-40": {
        plan: "ACCP102",
        min: 31,
        max: 40,
      },
      "41-50": {
        plan: "ACCP103",
        min: 41,
        max: 50,
      },
      "51-55": {
        plan: "ACCP104",
        min: 51,
        max: 55,
      }
    },
    policy: {
      coverageDuration: "One (1) Year",
      earlyStageCancerBenefit: "RM50,000",
      cancerBenefit: "RM100,000",
      CompassionateBenefit: "RM2,000",
    }
  },
  "go-getter": {
    name: "Go Getter",
    ages: {
      "18-30": {
        plan: "ACCP151",
        min: 18,
        max: 30,
      },
      "31-40": {
        plan: "ACCP152",
        min: 31,
        max: 40,
      },
      "41-50": {
        plan: "ACCP153",
        min: 41,
        max: 50,
      },
      "51-55": {
        plan: "ACCP154",
        min: 51,
        max: 55,
      }
    },
    policy: {
      coverageDuration: "One (1) Year",
      earlyStageCancerBenefit: "RM75,000",
      cancerBenefit: "RM150,000",
      CompassionateBenefit: "RM2,000",
    }
  }
};

export const travelIntPolicy = {
  O: [
    { name: "Personal Accident Benefits", value: "RM100,000" },
    { name: "Medical Expenses", value: "NA" },
    { name: "Emergency Medical Evaluation & Repatriation", value: "RM100,000" },
    { name: "Travel Inconvenience Benefit", value: "RM15,000" },
    { name: "Personal Liability", value: "NA" },
  ],
  T: [
    { name: "Personal Accident Benefits", value: "RM100,000" },
    { name: "Medical Expenses", value: "RM50,000" },
    { name: "Emergency Medical Evaluation & Repatriation", value: "RM100,000" },
    { name: "Travel Inconvenience Benefit", value: "RM20,000" },
    { name: "Personal Liability", value: "RM250,000" },
  ],
};

export const travelDomPolicy = {
  O: [
    { name: "Personal Accident Benefits", value: "RM100,000" },
    { name: "Medical Expenses", value: "NA" },
    { name: "Emergency Medical Evaluation & Repatriation", value: "RM100,000" },
    { name: "Travel Inconvenience Benefit", value: "RM9,500" },
    { name: "Personal Liability", value: "NA" },
  ],
  T: [
    { name: "Personal Accident Benefits", value: "RM100,000" },
    { name: "Medical Expenses", value: "RM25,000" },
    { name: "Emergency Medical Evaluation & Repatriation", value: "RM100,000" },
    { name: "Travel Inconvenience Benefit", value: "RM12,000" },
    { name: "Personal Liability", value: "RM250,000" },
  ],
};

export const ProtectPolicy = [
  { name: "Critical Illness : Major Head Trauma", value: "RM12,500" },
  { name: "Critical Illness : Coma", value: "RM12,500" },
  { name: "Accidental Death Benefit", value: "RM12,500" },
  { name: "Accidental Death while Traveling in Private Conveyance", value: "RM25,000" },
  { name: "Accidental Death while Traveling in Public Conveyance", value: "RM37,500" },
  { name: "Bereavement Benefit (Upon Accidental Death)", value: "RM5,000" },
];

export const hospiCashPolicy = [
  // {name: "Daily Hospital Income",  value: "RM 100 per day"},
  // {name: "+ Processing fee", value: "RM1.20"},
];

export const billProtectPolicy = [
  { name: "Accidental Death Benefit", value: "RM10,000" },
  { name: "Permanent Disability", value: "RM10,000" },
  { name: "Bill Pay Protect", value: "Up to RM1,000" },
];

export const hospicashQuestionnaire = [
  {
    question: "Are you currently receiving treatment or taking regular prescribed medication for any medical conditions (other than for common cold or flu)?",
    answer: "",
  },
  {
    question: "Have you ever been diagnosed with, consulted a medical practitioner or been given treatment for any of the following conditions ; Heart attack, chest pain, high cholesterol, stroke, hypertension, diabetes, cancer, tumour or abnormal growth, hepatitis, HIV infection or AIDS: - Any disease or disorder of the heart, brain or nervous system, eyes or ears, thyroid, lung or respiratory system, liver, digestive system or gastrointestinal tract, kidney or urinary system, genital organs, spine or joints, immune system, blood disorder, hereditary disease or congenital abnormality, mental health or psychiatric illness, physical impairment or deformity, drug or alcohol abuse?",
    answer: "",
  },
  {
    question: "In the past 2 years, have you ever been hospitalized (except for giving birth), undergone any surgical operation, consulted a specialist or had an abnormal result from a medical investigation or diagnostic test or been advised to have any of these in future?",
    answer: "",
  },
];

export const cardProtectQuestionnaire = [
  {
    question: "Are you currently receiving treatment or taking regular prescribed medication for any medical conditions (other than for common cold or flu)?",
    answer: "",
  },
  {
    question: "Have you ever been diagnosed with, consulted a medical practitioner or been given treatment for any of the following conditions ; Heart attack, chest pain, high cholesterol, stroke, hypertension, diabetes, cancer, tumour or abnormal growth, hepatitis, HIV infection or AIDS: - Any disease or disorder of the heart, brain or nervous system, eyes or ears, thyroid, lung or respiratory system, liver, digestive system or gastrointestinal tract, kidney or urinary system, genital organs, spine or joints, immune system, blood disorder, hereditary disease or congenital abnormality, mental health or psychiatric illness, physical impairment or deformity, drug or alcohol abuse?",
    answer: "",
  },
  {
    question: "In the past 2 years, have you ever been hospitalized (except for giving birth), undergone any surgical operation, consulted a specialist or had an abnormal result from a medical investigation or diagnostic test or been advised to have any of these in future?",
    answer: "",
  },
];

export const takafulUsahawan3in1Questionnaire = [
  {
    question: "Are you currently receiving treatment or taking regular prescribed medication for any medical conditions (other than for common cold or flu)?",
    answer: "",
  },
  {
    question: "Have you ever been diagnosed with, consulted a medical practitioner or been given treatment for any of the following conditions ; Heart attack, chest pain, high cholesterol, stroke, hypertension, diabetes, cancer, tumour or abnormal growth, hepatitis, HIV infection or AIDS: - Any disease or disorder of the heart, brain or nervous system, eyes or ears, thyroid, lung or respiratory system, liver, digestive system or gastrointestinal tract, kidney or urinary system, genital organs, spine or joints, immune system, blood disorder, hereditary disease or congenital abnormality, mental health or psychiatric illness, physical impairment or deformity, drug or alcohol abuse?",
    answer: "",
  },
  {
    question: "In the past 2 years, have you ever been hospitalized (except for giving birth), undergone any surgical operation, consulted a specialist or had an abnormal result from a medical investigation or diagnostic test or been advised to have any of these in future?",
    answer: "",
  },
];

export const cancerCarePrimeQuestionnaire = {
  en: [
    {
      question: "Have you had any medical consultation (other than for normal flu, cough and fever), had any signs or persistent symptoms of any kind of any disease or medical condition OR undergone any surgical procedures, investigation/diagnostic tests/biopsies, OR taken any prescribed medical treatment or been advised to have such consultation, tests or treatment for any reason OR are considering to have any medical consultation or tests for any reason OR any of your family members ever suffered from cancer before age 60? *",
      answer: "",
    },
    {
      question: "Do you have any abnormality OR have you ever had or received medical advice for any of the following illness, OR been referred for tests or investigations for any of these conditions: disease of the heart or blood circulatory system, chest pain, stroke, cancer or abnormal growth, hepatitis, thyroid problem, gastrointestinal problem, respiratory disease, liver disease, kidney disease, urinary or bowel disease, joints or muscle problem, HIV infection, AIDS related or any other sexually transmitted disease? *",
      answer: "",
    },
    {
      question: "Do you currently have, or are you currently receiving treatment for any symptoms, medical conditions or disability (other than normal flu, cough, fever), OR have you ever been hospitalized due to illness or injury for a continuous period of more than 3 days OR received medical treatment for a continuous period of more than 6 days during the last five year period? *",
      answer: "",
    }
  ],
  ms: [
    {
      question: "Adakah anda pernah menjalani konsultasi perubatan (selain selesema biasa, batuk dan demam), mempunyai tanda-tanda atau simptom berterusan dari sebarang jenis penyakit atau keadaan perubatan ATAU menjalani prosedur pembedahan, siasatan / ujian diagnostik / biopsi, ATAU mengambil preskripsi yang ditetapkan untuk rawatan perubatan atau dinasihatkan untuk mengadakan konsultasi, ujian atau rawatan untuk tujuan perubatan ATAU mempertimbangkan untuk menjalani konsultasi perubatan atau ujian dengan alasan apa pun ATAU mana-mana ahli keluarga anda pernah menghidap barah sebelum berumur 60 tahun? *",
      answer: "",
    },
    {
      question: "Adakah anda mengalami kelainan ATAU pernah mendapat konsultasi perubatan untuk salah satu penyakit berikut, ATAU telah dirujuk untuk menjalani ujian atau siasatan untuk penyakit-penyakit tersenarai: penyakit jantung atau sistem peredaran darah, sakit dada, strok, kanser atau pertumbuhan yang tidak normal, hepatitis, masalah tiroid, masalah gastrointestinal, penyakit pernafasan, penyakit hati, penyakit ginjal, penyakit kencing atau usus, masalah sendi atau otot, jangkitan HIV, AIDS atau penyakit kelamin lain? *",
      answer: "",
    },
    {
      question: "Adakah anda kini atau sedang menerima rawatan untuk sebarang rawatan penyakit, keadaan perubatan atau kecacatan (selain selesema biasa, batuk, demam), ATAU adakah anda pernah dimasukkan ke hospital kerana sakit atau kecederaan untuk jangka masa yang berterusan lebih dari 3 hari ATAU menerima rawatan perubatan untuk jangka masa berterusan lebih dari 6 hari dalam tempoh lima tahun terakhir? *",
      answer: "",
    }
  ],
};

// export const cancerCarePrimeQuestionnaire = [
//   {
//     question: "Have you had any medical consultation (other than for normal flu, cough and fever), had any signs or persistent symptoms of any kind of any disease or medical condition OR undergone any surgical procedures, investigation/diagnostic tests/biopsies, OR taken any prescribed medical treatment or been advised to have such consultation, tests or treatment for any reason OR are considering to have any medical consultation or tests for any reason OR any of your family members ever suffered from cancer before age 60? *",
//     answer: "",
//   },
//   {
//     question: "Do you have any abnormality OR have you ever had or received medical advice for any of the following illness, OR been referred for tests or investigations for any of these conditions: disease of the heart or blood circulatory system, chest pain, stroke, cancer or abnormal growth, hepatitis, thyroid problem, gastrointestinal problem, respiratory disease, liver disease, kidney disease, urinary or bowel disease, joints or muscle problem, HIV infection, AIDS related or any other sexually transmitted disease? *",
//     answer: "",
//   },
//   {
//     question: "Do you currently have, or are you currently receiving treatment for any symptoms, medical conditions or disability (other than normal flu, cough, fever), OR have you ever been hospitalized due to illness or injury for a continuous period of more than 3 days OR received medical treatment for a continuous period of more than 6 days during the last five year period? *",
//     answer: "",
//   },
// ]

export const domCountries = [
  { name: "Malaysia" }
];

export const paymentMethod = {
  boost: 1,
  eghl: 2
};
// export const paymentMethod = {
//   boost: "Boost",
//   eghl: "Online Payment"
// }

export const intCountries = [
  { area: 2, name: "Afghanistan" },
  { area: 2, name: "Albania" },
  { area: 2, name: "Algeria" },
  { area: 2, name: "American Samoa" },
  { area: 2, name: "Andorra" },
  { area: 2, name: "Angola" },
  { area: 2, name: "Anguilla" },
  { area: 2, name: "Antarctica" },
  { area: 2, name: "Antigua and Barbuda" },
  { area: 2, name: "Argentina" },
  { area: 2, name: "Armenia" },
  { area: 2, name: "Aruba" },
  { area: 2, name: "Austria" },
  { area: 1, name: "Australia" },
  { area: 2, name: "Azerbaijan" },
  { area: 2, name: "Bahamas" },
  { area: 2, name: "Bahrain" },
  { area: 2, name: "Bangladesh" },
  { area: 2, name: "Barbados" },
  { area: 2, name: "Belarus" },
  { area: 2, name: "Belgium" },
  { area: 2, name: "Belize" },
  { area: 2, name: "Benin" },
  { area: 2, name: "Bermuda" },
  { area: 2, name: "Bhutan" },
  { area: 2, name: "Bintan" },
  { area: 2, name: "Bolivia" },
  { area: 2, name: "Bosnia and Herzegovina" },
  { area: 2, name: "Botswana" },
  { area: 2, name: "Bouvet Island" },
  { area: 2, name: "Brazil" },
  { area: 2, name: "British Indian Ocean Territory" },
  { area: 1, name: "Brunei" },
  { area: 2, name: "Bulgaria" },
  { area: 2, name: "Burkina Faso" },
  { area: 2, name: "Burundi" },
  { area: 1, name: "Cambodia" },
  { area: 2, name: "Cameroon" },
  { area: 2, name: "Canada" },
  { area: 2, name: "Cape Verde" },
  { area: 2, name: "Cayman Islands" },
  { area: 2, name: "Central African Republic" },
  { area: 2, name: "Chad" },
  { area: 2, name: "Chile" },
  // {area: 1, name: "China"},
  { area: 2, name: "Christmas Island" },
  { area: 2, name: "Cocos (Keeling) Islands" },
  { area: 2, name: "Colombia" },
  { area: 2, name: "Comoros" },
  { area: 2, name: "Congo" },
  { area: 2, name: "Cook Islands" },
  { area: 2, name: "Costa Rica" },
  { area: 2, name: "Cote d'Ivoire" },
  { area: 2, name: "Croatia" },
  { area: 2, name: "Cyprus" },
  { area: 2, name: "Czech Republic" },
  { area: 2, name: "Denmark" },
  { area: 2, name: "Djibouti" },
  { area: 2, name: "Dominica" },
  { area: 2, name: "Dominican Republic" },
  { area: 2, name: "East Timor" },
  { area: 2, name: "Ecuador" },
  { area: 2, name: "Egypt" },
  { area: 2, name: "El Salvador" },
  { area: 2, name: "England" },
  { area: 2, name: "Equatorial Guinea" },
  { area: 2, name: "Eritrea" },
  { area: 2, name: "Estonia" },
  { area: 2, name: "Ethiopia" },
  { area: 2, name: "Falkland Islands (Malvinas)" },
  { area: 2, name: "Faroe Islands" },
  { area: 2, name: "Fiji" },
  { area: 2, name: "Finland" },
  { area: 2, name: "France" },
  { area: 2, name: "France, Metropolitan" },
  { area: 2, name: "French Guiana" },
  { area: 2, name: "French Polynesia" },
  { area: 2, name: "French Southern Territories" },
  { area: 2, name: "Gabon" },
  { area: 2, name: "Gambia" },
  { area: 2, name: "Georgia" },
  { area: 2, name: "Germany" },
  { area: 2, name: "Ghana" },
  { area: 2, name: "Gibraltar" },
  { area: 2, name: "Greece" },
  { area: 2, name: "Greenland" },
  { area: 2, name: "Grenada" },
  { area: 2, name: "Guadeloupe" },
  { area: 2, name: "Guam" },
  { area: 2, name: "Guatemala" },
  { area: 2, name: "Guinea" },
  { area: 2, name: "Guinea-Bissau" },
  { area: 2, name: "Guyana" },
  { area: 2, name: "Haiti" },
  { area: 2, name: "Heard and McDonald Islands" },
  { area: 2, name: "Honduras" },
  { area: 1, name: "Hong Kong" },
  { area: 2, name: "Hungary" },
  { area: 2, name: "Iceland" },
  { area: 1, name: "India" },
  { area: 1, name: "Indonesia" },
  { area: 2, name: "Iraq" },
  { area: 2, name: "Ireland" },
  { area: 2, name: "Italy" },
  { area: 2, name: "Jamaica" },
  { area: 1, name: "Japan" },
  { area: 2, name: "Jordan" },
  { area: 2, name: "Kazakhstan" },
  { area: 2, name: "Kenya" },
  { area: 2, name: "Kiribati" },
  { area: 2, name: "Kuwait" },
  { area: 2, name: "Kyrgyzstan" },
  { area: 2, name: "Laos" },
  { area: 2, name: "Latvia" },
  { area: 2, name: "Lebanon" },
  { area: 2, name: "Lesotho" },
  { area: 2, name: "Liberia" },
  { area: 2, name: "Libyan Arab Jamahiriya" },
  { area: 2, name: "Liechtenstein" },
  { area: 2, name: "Lithuania" },
  { area: 2, name: "Luxembourg" },
  { area: 1, name: "Macau" },
  { area: 2, name: "Macedonia" },
  { area: 2, name: "Madagascar" },
  { area: 2, name: "Malawi" },
  { area: 2, name: "Maldives" },
  { area: 2, name: "Mali" },
  { area: 2, name: "Malta" },
  { area: 2, name: "Marshall Islands" },
  { area: 2, name: "Martinique" },
  { area: 2, name: "Mauritania" },
  { area: 2, name: "Mauritius" },
  { area: 2, name: "Mayotte" },
  { area: 2, name: "Mexico" },
  { area: 2, name: "Micronesia" },
  { area: 2, name: "Moldova" },
  { area: 2, name: "Monaco" },
  { area: 2, name: "Mongolia" },
  { area: 2, name: "Monserrat" },
  { area: 2, name: "Montenegro" },
  { area: 2, name: "Morocco" },
  { area: 2, name: "Mozambique" },
  { area: 1, name: "Myanmar" },
  { area: 2, name: "Namibia" },
  { area: 2, name: "Nauru" },
  { area: 2, name: "Nepal" },
  { area: 2, name: "Netherlands" },
  { area: 2, name: "Netherlands Antilles" },
  { area: 2, name: "New Caledonia" },
  { area: 1, name: "New Zealand" },
  { area: 2, name: "Nicaragua" },
  { area: 2, name: "Niger" },
  { area: 2, name: "Nigeria" },
  { area: 2, name: "Niue" },
  { area: 2, name: "Norfolk Island" },
  { area: 2, name: "Northern Mariana Islands" },
  { area: 2, name: "Norway" },
  { area: 2, name: "Oman" },
  { area: 1, name: "Pakistan" },
  { area: 2, name: "Palau" },
  { area: 2, name: "Panama" },
  { area: 2, name: "Papua New Guinea" },
  { area: 2, name: "Paraguay" },
  { area: 2, name: "Peru" },
  { area: 1, name: "Philippines" },
  { area: 2, name: "Pitcairn" },
  { area: 2, name: "Poland" },
  { area: 2, name: "Portugal" },
  { area: 2, name: "Puerto Rico" },
  { area: 2, name: "Qatar" },
  { area: 2, name: "Reunion" },
  { area: 2, name: "Romania" },
  { area: 2, name: "Russia" },
  { area: 2, name: "Rwanda" },
  { area: 2, name: "Saint Kitts and Nevis" },
  { area: 2, name: "Saint Lucia" },
  { area: 2, name: "Saint Vincent and the Grenadines" },
  { area: 2, name: "Samoa" },
  { area: 2, name: "San Marino" },
  { area: 2, name: "Sao Tome and Principe" },
  { area: 2, name: "Saudi Arabia" },
  { area: 2, name: "Senegal" },
  { area: 2, name: "Seychelles" },
  { area: 2, name: "Sierra Leone" },
  { area: 1, name: "Singapore" },
  { area: 2, name: "Slovakia" },
  { area: 2, name: "Slovenia" },
  { area: 2, name: "Solomon Islands" },
  { area: 2, name: "Somalia" },
  { area: 2, name: "South Africa" },
  { area: 2, name: "South Georgia and S.Sandwich Islands" },
  { area: 1, name: "South Korea" },
  { area: 2, name: "Spain" },
  { area: 1, name: "Sri Lanka" },
  { area: 2, name: "St. Helena" },
  { area: 2, name: "St. Pierre and Miquelon" },
  { area: 2, name: "Suriname" },
  { area: 2, name: "Svalbard and Jan Mayen Islands" },
  { area: 2, name: "Swaziland" },
  { area: 2, name: "Sweden" },
  { area: 2, name: "Switzerland" },
  { area: 1, name: "Taiwan" },
  { area: 2, name: "Tajikistan" },
  { area: 2, name: "Tanzania" },
  { area: 1, name: "Thailand" },
  { area: 2, name: "Togo" },
  { area: 2, name: "Tokelau" },
  { area: 2, name: "Tonga" },
  { area: 2, name: "Trinidad and Tobago" },
  { area: 2, name: "Tunisia" },
  { area: 2, name: "Turkey" },
  { area: 2, name: "Turkmenistan" },
  { area: 2, name: "Turks and Caicos Islands" },
  { area: 2, name: "Tuvalu" },
  { area: 2, name: "Uganda" },
  { area: 2, name: "United Arab Emirates" },
  { area: 2, name: "United Kingdom" },
  { area: 2, name: "United States Minor Outlying Islands" },
  { area: 2, name: "United States of America" },
  { area: 2, name: "Uruguay" },
  { area: 2, name: "Uzbekistan" },
  { area: 2, name: "Vanuatu" },
  { area: 2, name: "Vatican City State (Holy See)" },
  { area: 2, name: "Venezuela" },
  { area: 1, name: "Vietnam" },
  { area: 2, name: "Virgin Islands (British)" },
  { area: 2, name: "Virgin Islands (U.S.)" },
  { area: 2, name: "Wallis and Futuna Islands" },
  { area: 2, name: "Western Sahara" },
  { area: 2, name: "Yemen" },
  { area: 2, name: "Yugoslavia" },
  { area: 2, name: "Zaire" },
  { area: 2, name: "Zambia" },
  { area: 2, name: "Zimbabwe" },
];

export const occupations = [
  { code: "ACCT", name: "Accountant" },
  { code: "ACON", name: "Applications Consultant" },
  { code: "ACOR", name: "Airfreight Co-Ordinator" },
  { code: "ACTR", name: "Actor/Actress" },
  { code: "ADAS", name: "Admin Assistant" },
  { code: "ADCL", name: "Admitting Clerk" },
  { code: "ADEX", name: "Advertising Executive" },
  { code: "AFST", name: "Aircon Servicing Technician" },
  { code: "AGET", name: "Agent (Insurance)" },
  { code: "AMBL", name: "Ambulanceman" },
  { code: "AMEX", name: "Admin Executive" },
  { code: "ANAE", name: "Anaethesian" },
  { code: "ANIM", name: "Animator" },
  { code: "ANKR", name: "Animal Keeper" },
  { code: "ANYS", name: "Analyst" },
  { code: "APPT", name: "Apprentices" },
  { code: "ARCH", name: "Architect" },
  { code: "ARTD", name: "Art Director" },
  { code: "ARTS", name: "Artisan" },
  { code: "ASFT", name: "Assembly Fitters" },
  { code: "AUDI", name: "Auditor" },
  { code: "BAKE", name: "Baker" },
  { code: "BANK", name: "Banker" },
  { code: "BAPR", name: "Baggage Porter" },
  { code: "BARB", name: "Barbers" },
  { code: "BARM", name: "Barman" },
  { code: "BARR", name: "Barrister" },
  { code: "BART", name: "Bartender" },
  { code: "BCON", name: "Business Consultant" },
  { code: "BDOF", name: "Boarding Officer" },
  { code: "BDVO", name: "Business Development Officer" },
  { code: "BEAU", name: "Beauticians" },
  { code: "BELL", name: "Bell Boy" },
  { code: "BEVE", name: "Beverage Dispenser" },
  { code: "BIAS", name: "Billing Assistant" },
  { code: "BILL", name: "Bill Collectors" },
  { code: "BLGS", name: "Building Surveyor" },
  { code: "BLMO", name: "Building Maintenance Officer" },
  { code: "BLRM", name: "Boilerman" },
  { code: "BOOK", name: "Book Keeper" },
  { code: "BRMM", name: "Building Repairman" },
  { code: "BROK", name: "Brokers" },
  { code: "BTLR", name: "Butler" },
  { code: "BULD", name: "Builder" },
  { code: "BUSD", name: "Bus Conductor or Driver" },
  { code: "BUYS", name: "Buyers" },
  { code: "CAAS", name: "Catering Assistant" },
  { code: "CADD", name: "Caddie Master" },
  { code: "CAHD", name: "Cargohand" },
  { code: "CAMP", name: "Campus Attendant" },
  { code: "CASH", name: "Cashier" },
  { code: "CASU", name: "Casual Workers" },
  { code: "CBJT", name: "Cable Jointer" },
  { code: "CBMD", name: "Chambermaid" },
  { code: "CESU", name: "Container Equipment Supervisor" },
  { code: "CFDL", name: "Chief Dealer" },
  { code: "CGSV", name: "Cargo Surveyor" },
  { code: "CHCP", name: "Chargehand/Carpenter" },
  { code: "CHEF", name: "Chef-De-Partie" },
  { code: "CINS", name: "Curtain Installer" },
  { code: "CISC", name: "Cisco" },
  { code: "CLAS", name: "Clinic Assistant" },
  { code: "CLCW", name: "Clearance Crew" },
  { code: "CLEA", name: "Cleaning Supervisor" },
  { code: "CLMA", name: "Claims Assistant" },
  { code: "CLNR", name: "Cleaner" },
  { code: "CLOF", name: "Clerical Officer" },
  { code: "CLTY", name: "Clerk/Typist" },
  { code: "CMGA", name: "Computer Graphic Assistant" },
  { code: "CNTR", name: "Control Room Operator" },
  { code: "COCL", name: "Computer Clerk" },
  { code: "CODL", name: "Corporate Dealer" },
  { code: "COMM", name: "Commis Cook" },
  { code: "CONS", name: "Consultant (Medical)" },
  { code: "CONT", name: "Container Clerk" },
  { code: "COOK", name: "Cook" },
  { code: "COPR", name: "Computer Programmer" },
  { code: "COUR", name: "Course Workers" },
  { code: "CPAT", name: "Car Park Attendant" },
  { code: "CRAN", name: "Credit Analyst" },
  { code: "CREW", name: "Counter Crew" },
  { code: "CROP", name: "Crane Operator" },
  { code: "CSAS", name: "Cost Assistant" },
  { code: "CSER", name: "Civil Servants" },
  { code: "CSTE", name: "Civil Structural Engineer" },
  { code: "CTPF", name: "Control Panel Fitter" },
  { code: "CULL", name: "Cullotine Operator" },
  { code: "CUST", name: "Custom & Excise Officer" },
  { code: "CVSC", name: "Conveyancing Search Clerk" },
  { code: "CWGR", name: "Car Washer/Greaser" },
  { code: "DECL", name: "Data Entry Clerk" },
  { code: "DELI", name: "Deliveryman" },
  { code: "DENT", name: "Dental Surgeons" },
  { code: "DESP", name: "Despatch Riders" },
  { code: "DGSP", name: "Diagnostic Specialist" },
  { code: "DHWR", name: "Dishwasher" },
  { code: "DIEC", name: "Die-Cutting Machine Operator" },
  { code: "DIR", name: "Director" },
  { code: "DIVE", name: "Diver (Commercial/Military)" },
  { code: "DNCR", name: "Dancer" },
  { code: "DOCT", name: "Doctor" },
  { code: "DOMS", name: "Domestic Servant" },
  { code: "DOOR", name: "Doorman" },
  { code: "DOUR", name: "Door Usher" },
  { code: "DPNR", name: "Dispenser" },
  { code: "DRAF", name: "Draftperson" },
  { code: "DRAU", name: "Draughtmen" },
  { code: "DRES", name: "Dressmakers" },
  { code: "DRIV", name: "Driver" },
  { code: "DRYC", name: "Dry Cleaners" },
  { code: "DSAS", name: "Distributor Assistant" },
  { code: "DSNR", name: "Designer" },
  { code: "DWSF", name: "Drinks Waitstaff" },
  { code: "EDIT", name: "Editors" },
  { code: "EDPO", name: "EDP Operator" },
  { code: "ELEC", name: "Electric Motor Rewinder" },
  { code: "ELET", name: "Electricians" },
  { code: "ELEV", name: "Elevator Attendant" },
  { code: "EMTO", name: "Estate Maintenance Officer" },
  { code: "ENGR", name: "Engineer" },
  { code: "ESTA", name: "Estate Agents" },
  { code: "EXEC", name: "Executive" },
  { code: "EXPR", name: "Exhibition Promoter" },
  { code: "FACT", name: "Factory Workers" },
  { code: "FAGA", name: "F A Graphic Artist" },
  { code: "FBCH", name: "F & B Cashier" },
  { code: "FBSC", name: "F & B Service Crew" },
  { code: "FCON", name: "Fashion Consultant" },
  { code: "FCSP", name: "Facilities Specialist" },
  { code: "FECD", name: "Foreign Ex-Change Dealer" },
  { code: "FILE", name: "Filing Clerk" },
  { code: "FIRE", name: "Fireman" },
  { code: "FISH", name: "Fishmonger" },
  { code: "FKOP", name: "Forklift Operator/Driver" },
  { code: "FLAS", name: "Film Layout Assistant" },
  { code: "FLHK", name: "Floor Housekeeper" },
  { code: "FLIG", name: "Flight Steward/Stewardess" },
  { code: "FLOR", name: "Florists" },
  { code: "FMKJ", name: "Female/Male K J" },
  { code: "FNCT", name: "Financial Controller" },
  { code: "FOOT", name: "Footballer" },
  { code: "FORC", name: "Foreign Currency Agent" },
  { code: "FORE", name: "Foreman" },
  { code: "FORX", name: "Forex Remisier" },
  { code: "FOUD", name: "Foundry Worker" },
  { code: "FTTR", name: "Fitness Trainer" },
  { code: "FURD", name: "Funiture Dealers" },
  { code: "FURN", name: "Furnacemen" },
  { code: "GARD", name: "Gardener" },
  { code: "GARW", name: "Garage Worker" },
  { code: "GASA", name: "Gas Attendant" },
  { code: "GASD", name: "Gas Distributor" },
  { code: "GOLD", name: "Goldsmiths" },
  { code: "GOLF", name: "Golf Course Maintenance Worker" },
  { code: "GOND", name: "Gondola Supervisor" },
  { code: "GREE", name: "Greengrocers" },
  { code: "GROP", name: "Guest Relations Officer" },
  { code: "HAIR", name: "Hairdresser & Beauty Culture" },
  { code: "HAND", name: "Handyman" },
  { code: "HAWK", name: "Hawker" },
  { code: "HEAL", name: "Health Inspectors" },
  { code: "HEAT", name: "Heat Treatment Technician" },
  { code: "HOAT", name: "Hospital Attendant" },
  { code: "HOLC", name: "Hotel & Restaurant Chefs" },
  { code: "HOSP", name: "Hospital Care-Taker" },
  { code: "HOTL", name: "Hotel & Restaurant Captains" },
  { code: "HOTW", name: "Hotel & Restaurant Waiters" },
  { code: "HROF", name: "Human Resource Officer" },
  { code: "HSCL", name: "Housekeeping Clerk" },
  { code: "HSEM", name: "Houseman" },
  { code: "HSEO", name: "House Officer" },
  { code: "HSWF", name: "Housewife" },
  { code: "IMEX", name: "Importer & Exporter" },
  { code: "IMMI", name: "Immigration Officer" },
  { code: "INCO", name: "Income Accountant" },
  { code: "INDE", name: "Interior Decorators" },
  { code: "INFO", name: "Information System Officer" },
  { code: "INHD", name: "In-house Detective" },
  { code: "INOA", name: "Incentive Operation Assistant" },
  { code: "INOT", name: "Inward/Outward Executive" },
  { code: "INST", name: "Instructor" },
  { code: "INTV", name: "Interviewer" },
  { code: "ISAB", name: "Isabela Occupation" },
  { code: "IVAS", name: "Inventory Assistant" },
  { code: "JACK", name: "Jackpot Attendant" },
  { code: "JOCK", name: "Jockey" },
  { code: "JOUR", name: "Journalist/Reporter" },
  { code: "KENN", name: "Kennel Assistant" },
  { code: "KTVR", name: "KTV Receptionist" },
  { code: "LABR", name: "Labourer" },
  { code: "LAUN", name: "Laundryman" },
  { code: "LAYO", name: "Layout Artist" },
  { code: "LCTR", name: "Lecturer" },
  { code: "LDHS", name: "Leading Hostess" },
  { code: "LDSV", name: "Land Surveyor" },
  { code: "LEAS", name: "Legal Assistant" },
  { code: "LFAT", name: "Lift Attendant" },
  { code: "LFGD", name: "Lifeguard" },
  { code: "LINE", name: "Line Leader" },
  { code: "LNEX", name: "Liner Executive" },
  { code: "LOCK", name: "Locker Room Attendant" },
  { code: "LPPO", name: "Label Press Printing Operator" },
  { code: "LRAT", name: "Linen Room Attendant" },
  { code: "MACH", name: "Machinist" },
  { code: "MAHD", name: "Material Handlers" },
  { code: "MAIN", name: "Maintenance" },
  { code: "MASS", name: "Masseur" },
  { code: "MCAS", name: "Merchandising Assistant" },
  { code: "MECH", name: "Mechanic" },
  { code: "MEEN", name: "Mechanical Engineer" },
  { code: "MGR", name: "Manager" },
  { code: "MINI", name: "Mini Laboratory Operator" },
  { code: "MKAN", name: "Market Analyst" },
  { code: "MKTG", name: "Marketing Executive" },
  { code: "MOAD", name: "Module Administrator" },
  { code: "MOCG", name: "Money Changer" },
  { code: "MOMK", name: "Mould Maker" },
  { code: "MONL", name: "Money Lender" },
  { code: "MOPR", name: "Machine Operators" },
  { code: "MRTC", name: "Train Driver" },
  { code: "MTEX", name: "Material Expeditor" },
  { code: "MTFB", name: "Metal Fabricator" },
  { code: "MUSI", name: "Musician" },
  { code: "NSVC", name: "National Services Man" },
  { code: "NURS", name: "Nurse" },
  { code: "OFFM", name: "Offset Machine Operator" },
  { code: "OILW", name: "Oil Refinery Worker" },
  { code: "OPTI", name: "Optician" },
  { code: "ORDR", name: "Order Management" },
  { code: "OTHE", name: "Occupation Therapist" },
  { code: "OTHR", name: "Other Occupation" },
  { code: "OUTB", name: "Outbound Officer" },
  { code: "OUTS", name: "Outstation Cashier" },
  { code: "PACK", name: "Package Delivery" },
  { code: "PAIN", name: "Painter" },
  { code: "PAST", name: "Pastry Chef" },
  { code: "PAWN", name: "Pawnbroker" },
  { code: "PBLS", name: "Publisher" },
  { code: "PCKR", name: "Packers" },
  { code: "PCOD", name: "Production Co-Ordinator" },
  { code: "PCOF", name: "Purchansing Officer" },
  { code: "PEPA", name: "Petrol Pump Attendant" },
  { code: "PEST", name: "Pest Control" },
  { code: "PFAS", name: "Proofing Assistant" },
  { code: "PGBY", name: "Page Boys" },
  { code: "PGRA", name: "Photographer" },
  { code: "PGSA", name: "Passenger Service Assistant" },
  { code: "PHAR", name: "Pharmacist" },
  { code: "PHAS", name: "Photo Assistant" },
  { code: "PHYS", name: "Physiotherapist" },
  { code: "PLAN", name: "Planner" },
  { code: "PLOT", name: "Pilot" },
  { code: "PLTE", name: "Plant Technician" },
  { code: "PLTN", name: "Politician" },
  { code: "PLUM", name: "Plumber" },
  { code: "PNTR", name: "Printer" },
  { code: "POLM", name: "Policeman" },
  { code: "POSC", name: "Pos Cashier" },
  { code: "POST", name: "Postman" },
  { code: "POTO", name: "Photocopy Assistant" },
  { code: "PRAG", name: "Property Agent" },
  { code: "PRCO", name: "Project Co-ordinator" },
  { code: "PROF", name: "Professor" },
  { code: "PROG", name: "Programmer" },
  { code: "PSAS", name: "Personnel Assistant" },
  { code: "PSAW", name: "PSA Whaft" },
  { code: "PTEI", name: "Private Investigator" },
  { code: "PTRM", name: "Photo Room Techinician" },
  { code: "PYCL", name: "Payroll Clerk" },
  { code: "PYMD", name: "Pantry Maid" },
  { code: "QCCK", name: "Q C Checkers" },
  { code: "RECP", name: "Receptionist" },
  { code: "REIN", name: "Reinsurance Executive" },
  { code: "REOF", name: "Recruitment Officer" },
  { code: "RESO", name: "Resort Executive" },
  { code: "RETL", name: "Retailers" },
  { code: "REWK", name: "Rework Technician" },
  { code: "RMSV", name: "Room Service" },
  { code: "RMVR", name: "Removers" },
  { code: "RSMR", name: "Regional Sales Manager" },
  { code: "RTEC", name: "Radio & TV Technician" },
  { code: "RTHE", name: "Radiotherapist" },
  { code: "SAFE", name: "Safety Officer" },
  { code: "SAIL", name: "Seaman/Sailor" },
  { code: "SALE", name: "Salesmen" },
  { code: "SANI", name: "Sanitary Inspector" },
  { code: "SCAN", name: "Scanner Assistant" },
  { code: "SECG", name: "Security Guard" },
  { code: "SECR", name: "Secretary" },
  { code: "SFOA", name: "Sea Freight Operation Asst" },
  { code: "SHCL", name: "Shipping Clerk" },
  { code: "SHEE", name: "Sheet Metal Fabricator" },
  { code: "SHIP", name: "Shipyard Worker" },
  { code: "SHKP", name: "Shopkeeper & Assistant" },
  { code: "SHOP", name: "Shop Assistant" },
  { code: "SHUT", name: "Shuttle Driver" },
  { code: "SIGN", name: "Signal Man" },
  { code: "SILK", name: "Silk Screen Auto Machine Op" },
  { code: "SING", name: "Singer" },
  { code: "SNGL", name: "Senior Glass Glazier" },
  { code: "SNWR", name: "Signwriter" },
  { code: "SOAT", name: "Sales Order Assistant" },
  { code: "SOCI", name: "Social Worker" },
  { code: "SOLR", name: "Solicitor" },
  { code: "SOPT", name: "Site Operation Technologist" },
  { code: "SPAT", name: "Swimming Pool Attendant" },
  { code: "SPFT", name: "Skilled Pipe Fitter" },
  { code: "SPOR", name: "Sport Complex Assistant" },
  { code: "SPOT", name: "Professional Sportsman" },
  { code: "SSAS", name: "Supermart Sales Assistant" },
  { code: "STAG", name: "Site Agent" },
  { code: "STAL", name: "Stall Helper" },
  { code: "STCL", name: "Site Clerk" },
  { code: "STCN", name: "Stock Controller" },
  { code: "STFM", name: "Site Foreman" },
  { code: "STKB", name: "Stockbroker" },
  { code: "STRM", name: "Storeman" },
  { code: "STUD", name: "Student" },
  { code: "STWD", name: "Stewarding" },
  { code: "SUTE", name: "Set-Up Technician" },
  { code: "SVCM", name: "Serviceman" },
  { code: "SWPR", name: "Software Professional" },
  { code: "SYAN", name: "System Analyst" },
  { code: "SYRU", name: "Syrup Process Operator" },
  { code: "TAIL", name: "Tailor" },
  { code: "TAXI", name: "Taxi Driver" },
  { code: "TCHR", name: "Teacher" },
  { code: "TDRS", name: "Traders" },
  { code: "TEAC", name: "Teacher" },
  { code: "TECH", name: "Technician" },
  { code: "TECO", name: "Transhipment Export Officer" },
  { code: "TELE", name: "Telemarketeer" },
  { code: "TELL", name: "Tellers" },
  { code: "TELX", name: "Telex Operator" },
  { code: "TFPO", name: "Traffic Police" },
  { code: "TICK", name: "Ticketing/Tour Executive" },
  { code: "TILE", name: "Tiler" },
  { code: "TOOL", name: "Toolsetter" },
  { code: "TOUR", name: "Tour Guide" },
  { code: "TRAD", name: "Tradenet Clerk" },
  { code: "TRAF", name: "Traffic & Warehouse Officer" },
  { code: "TRAS", name: "Treasury Assistant" },
  { code: "TRAV", name: "Travel Agency" },
  { code: "TRIC", name: "Trichologist" },
  { code: "TSSP", name: "Technical Support Specialist" },
  { code: "TUTO", name: "Tutor" },
  { code: "TXAS", name: "Tax Assistant" },
  { code: "TYPT", name: "Typist" },
  { code: "TYRE", name: "Tyreman" },
  { code: "UNDA", name: "Underwriting Assistant" },
  { code: "VALE", name: "Valet" },
  { code: "VDED", name: "Video Editor" },
  { code: "VLAS", name: "Van/Lorry Attendant" },
  { code: "WARD", name: "Warden" },
  { code: "WARE", name: "Warehouse Clerk" },
  { code: "WARP", name: "Watch Repairer" },
  { code: "WASH", name: "Washromm Attendant" },
  { code: "WELD", name: "Welders" },
  { code: "WIRE", name: "Wireman" },
  { code: "WORD", name: "Wordrobe Officer" },
  { code: "WORK", name: "Workshop Assistant" },
  { code: "WRTR", name: "Writer" },
];

export const nationalityOptions = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Côte d'Ivoire",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo (Congo-Brazzaville)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia (Czech Republic)",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini (fmr. \"Swaziland\")",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Holy See",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (formerly Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine State",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];

export const identificationTypes = [
  { name: "NRIC (MyKadNo.)", value: "nric" },
  { name: "Passport No.", value: "passport" },
  // {name: "Police / Army No.", value: "policeOrArmy"},
];

export const salutation = [
  "Datin",
  "Dato",
  "Dr",
  "Madam",
  "Mr",
  "Mrs",
  "Ms",
];

export const genders = [
  { name: "Male", value: "M" },
  { name: "Female", value: "F" },
];

export const myStates = [
  { name: "Kuala Lumpur" },
  { name: "Johor" },
  { name: "Kedah" },
  { name: "Kelantan" },
  { name: "Malacca" },
  { name: "Negeri Sembilan" },
  { name: "Pahang" },
  { name: "Perak" },
  { name: "Perlis" },
  { name: "Penang" },
  { name: "Sabah" },
  { name: "Sarawak" },
  { name: "Selangor" },
  { name: "Terengganu" },
];

export const GAEventCategory = {
  [PRODUCT_PROTECT_PATH]: "Insurance Protect",
  [PRODUCT_PROTECT_BOOST_PATH]: "Insurance Protect",
  [PRODUCT_PROTECT_SUPER_6_PATH]: "Insurance Protect Super6",
  [PRODUCT_CARDPROTECT_PATH]: "Insurance CardProtect",
  [PRODUCT_HOSPITAL_CASH_PATH]: "Insurance HospiCash",
  [PRODUCT_BILLPROTECT_PATH]: "Insurance BillProtect",
  [PRODUCT_TRAVEL_DOMESTIC_PATH]: "Insurance TravelLocal",
  [PRODUCT_TRAVEL_INTERNATIONAL_PATH]: "Insurance TravelWorldWide",
  [PRODUCT_SME_OWNERPROTECT_PATH]: "Insurance SME OwnerProtect",
  [PRODUCT_TAKAFUL_USAHAWAN_3_IN_1_PATH]: "Insurance Takaful Usahawan 3in1",
  [PRODUCT_CELCOM_CPROTECT_PATH]: "Insurance cProtect",
  [PRODUCT_CANCER_CARE_PRIME_PATH]: "Insurance Cancer Care Prime"
};

export const GAEventPlanCode = (category, params) => {
  const plan = params.get("plan");
  if (category === PRODUCT_CARDPROTECT_PATH) {
    return cardProtectPlan[plan]?.plan;
  } if (category === PRODUCT_SME_OWNERPROTECT_PATH) {
    const utmPartner = params.get("utm_partner");
    return smeOwnerProtectPlan[plan] ? smeOwnerProtectPlan[plan]?.plan : ((utmPartner?.toLowerCase() === "boost_business") ? "ASOP15" : "ASOP365");
  } if (category === PRODUCT_TAKAFUL_USAHAWAN_3_IN_1_PATH) {
    return takafulUsahawan3in1Plan[plan]?.plan;
  } if (category === PRODUCT_CANCER_CARE_PRIME_PATH) {
    return cancerCarePrimePlan[plan]?.ages[params.get.age]?.plan;
  }
  return null;
};
