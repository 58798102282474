import React from "react";
import "./page-loader.scss";

const PageLoader = () => {
  return (
    <div className="page-loader">
      <div className="loader-holder">
        <div className="spinner-grow loader" role="status" />
        <div className="spinner-grow loader" role="status" />
        <div className="spinner-grow loader" role="status" />
      </div>
    </div>
  );
};

const SuspensePageLoader = () => {
  return (
    <div className="page-loader suspense">
      <div className="loader-holder">
        <div className="spinner-grow loader" role="status" />
        <div className="spinner-grow loader" role="status" />
        <div className="spinner-grow loader" role="status" />
      </div>
    </div>
  );
};

export {
  PageLoader as default,
  SuspensePageLoader
};
