import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import React, { Component } from "react";

const Link = (props) => {
  const location = useLocation();
  const appendQueryString = () => {
    const { to } = props;

    // Handling for parent / child products to remove selected product option
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params["product-option"]) {
      delete params["product-option"];
    }

    return params ? `${to}?${new URLSearchParams(params).toString()}` : to;
  };

  const to = appendQueryString();
  return (
    <ReactRouterLink {...props} to={to}>
      {props.children}
    </ReactRouterLink>
  );
};

export { Link };
export default Link;
