import styled, { css, keyframes } from "styled-components";
import { headerHeight } from "../Header/Styled";
import { COLORS } from "../../../constants/variables";

const popUpAnimation = keyframes(css`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`);

const fadeIn = keyframes(css`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`);

export const MiddlePopupWrapper = styled("div")`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
  transform: translateX(0);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: ${(props) => (props.textContent ? "scroll" : "")};
  padding-block: ${(props) => (props.textContent ? "50px" : "")};
  &.fading {
    opacity: 0;
    &.left {
      transform: translateX(-100%);
    }
    &.right {
      transform: translateX(100%);
    }
  }
  &.fade-in {
    opacity: 0;
    animation: ${fadeIn} 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
  .content {
    width: ${(props) => (props.textContent ? "95vw" : "480px")};
    max-width: 900px;
    height: ${(props) => (props.textContent ? "auto" : "480px")};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    padding: ${(props) => (props.textContent ? "40px 80px" : "")};
    border-radius: 8px;
    position: relative;
    background-image: ${(props) => `url(${props.background})`};
    background-color: ${COLORS.primaryRed};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    animation: ${popUpAnimation} 300ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
    @media screen and (max-width: 900px) {
      width: ${(props) => (props.textContent ? "85vw" : "320px")};
      /* max-width: 1100px; */
      height: ${(props) => (props.textContent ? "auto" : "320px")};
      padding: ${(props) => (props.textContent ? "30px 40px" : "")};
      background-image: ${(props) => `url(${props.backgroundMobile})`};
      margin: ${(props) => (props.textContent ? "" : "0 auto")};
    }
    @media screen and (max-width: 480px) {
      padding: ${(props) => (props.textContent ? "10px 20px" : "")};
    }
    > a {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-decoration: none;
    }
    .text-container{
      display: flex;
      flex-direction: column;
      /* max-width: 1180px; */
      align-items: flex-start;
      justify-content: center;
      margin: 0 auto;
      height: 100%;
      padding-right: 15px;
    }
    }
    .title{
      font-size: 2.375rem;
      color: #FFF;
      font-weight: 400;
      text-align: left;
      margin-block: 0;
    }
    .text-content{
      font-size: 1.125rem;
      color: #FFF;
      text-align: left;
      line-height: 2.25rem;
      margin: 0 auto;

      @media screen and (max-width: 900px) {
        p{
          margin-block: 0.5rem;
        }

        border-left: 3px solid rgba(102, 107, 122, 0.8);
        padding-left: 15px;
      }

      p:first-child{
        margin-block-start: 0;
      }

      p:last-child{
        margin-block-end: 0;
      }
  }

  .close-ads {
    position: absolute;
    background: #fff;
    outline: none;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #102938;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    top: -12px;
    right: -12px;
    > svg {
      font-size: 16px;
      color: #102938;
    }
  }
`;
