import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga";
import api from "../../utils/api";
import {
  IS_LOADING,
  SET_DATA,
  RESET_DATA,
  SET_STEP,
  SET_DIALOG,
  SET_PAYMENT_DIALOG,
} from "../types";
import { deviceInfo } from "../../../../../analytics/index";
import {
  PRODUCT_HOSPITAL_CASH_PATH,
  PRODUCT_CARDPROTECT_PATH,
  PRODUCT_PROTECT_SUPER_6_PATH,
} from "../../utils";

export const useActions = (state, dispatch) => {
  return {
    setStep: (data) => {
      dispatch({ type: SET_STEP, data });
    },
    setLoading: (data) => {
      dispatch({ type: IS_LOADING, data });
    },
    setData: (data, name) => {
      dispatch({ type: SET_DATA, data, name });
    },
    resetData: () => {
      dispatch({ type: RESET_DATA });
    },
    setPayemtnDialog: (data) => {
      dispatch({ type: SET_PAYMENT_DIALOG, data });
    },
    getPremium: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/get-premium", data).then((res) => {
        dispatch({ type: SET_DATA, data: res.data.data[0], name: "payloadPremium" });
        dispatch({ type: SET_STEP, data: 1 });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    saveApplication: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/save-application", data).then((res) => {
        // analytics
        deviceInfo("INSURANCE", "SIGN UP - SAVE APPPLICATION", res.data.data.applicationId);
        //
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
        dispatch({ type: SET_STEP, data: 2 });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    saveProtectApplication: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/save-life-application", data).then((res) => {
        // analytics
        deviceInfo("INSURANCE", "SIGN UP - SAVE LIFE APPPLICATION", res.data.data.applicationId);
        //
        mixpanel.alias(data.email);
        mixpanel.track("save-life-application", { "status": "success" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "name": data.name,
          "policyEffectiveDate": data.policyEffectiveDate,
          "nric": data.nric,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "success",
        });
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
        dispatch({ type: SET_STEP, data: 2 });
      }).catch((error) => {
        mixpanel.alias(data.email);
        mixpanel.track("save-life-application", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "$name": data.name,
          "policyEffectiveDate": data.policyEffectiveDate,
          "nric": data.nric,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    saveProtectBoostApplication: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/save-boost-protect", data).then((res) => {
        // analytics
        deviceInfo("INSURANCE", "SIGN UP - BOOST PROTECT", res.data.data.applicationId);
        //
        mixpanel.alias(data.email);
        mixpanel.track("save-boost-protect", { "status": "success" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "name": data.name,
          "policyEffectiveDate": data.policyEffectiveDate,
          "nric": data.nric,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "success",
        });
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
        dispatch({ type: SET_STEP, data: 2 });
      }).catch((error) => {
        mixpanel.alias(data.email);
        mixpanel.track("save-boost-protect", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "$name": data.name,
          "policyEffectiveDate": data.policyEffectiveDate,
          "nric": data.nric,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    saveProtectSuper6Application: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/save-protect-super6", data).then((res) => {
        // analytics
        deviceInfo("INSURANCE", "SIGN UP - PROTECT SUPER 6", res.data.data.applicationId);
        //
        mixpanel.alias(data.email);
        mixpanel.track("save-protect-super6", { "status": "success" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "success",
        });
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
        dispatch({ type: SET_STEP, data: 2 });
      }).catch((error) => {
        mixpanel.alias(data.email);
        mixpanel.track("save-protect-super6", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "$name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    saveHospiCashApplication: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/save-hospicash", data).then((res) => {
        // analytics
        deviceInfo("INSURANCE", "SIGN UP - HOSPICASH", res.data.data.applicationId);
        //
        mixpanel.alias(data.email);
        mixpanel.track("save-hospicash", { "status": "success" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "success",
        });
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
        dispatch({ type: SET_STEP, data: 2 });
      }).catch((error) => {
        mixpanel.alias(data.email);
        mixpanel.track("save-hospicash", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "$name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    saveBillProtectApplication: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/save-bill-protect", data).then((res) => {
        // analytics
        deviceInfo("INSURANCE", "SIGN UP - BILL PROTECT", res.data.data.applicationId);
        //
        mixpanel.alias(data.email);
        mixpanel.track("save-bill-protect", { "status": "success" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "success",
        });
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
        dispatch({ type: SET_STEP, data: 2 });
      }).catch((error) => {
        mixpanel.alias(data.email);
        mixpanel.track("save-bill-protect", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "$name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    saveCardProtectApplication: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/save-card-protect", data).then((res) => {
        // analytics
        deviceInfo("INSURANCE", "SIGN UP - CARD PROTECT", res.data.data.applicationId);
        //
        mixpanel.alias(data.email);
        mixpanel.track("save-card-protect", { "status": "success" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "planCode": data.planCode,
          "status": "success",
        });
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
        dispatch({ type: SET_STEP, data: 2 });
      }).catch((error) => {
        mixpanel.alias(data.email);
        mixpanel.track("save-card-protect", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "$name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "planCode": data.planCode,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    saveCelcomCProtectApplication: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/save-c-protect", data).then((res) => {
        // analytics
        deviceInfo("INSURANCE", "SIGN UP - CELCOM cPROTECT", res.data.data.applicationId);
        //
        if (res.status === 200) {
          ReactGA.event({ category: "Insurance cProtect", action: "Success" });
          mixpanel.identify(data.transactionId);
          mixpanel.track("save-c-protect", { "status": "success" });
          mixpanel.people.set({
            "$distinct_id": data.transactionId,
            "transactionId": data.transactionId,
            "$name": data.name,
            "identificationType": data.identificationType,
            "identification": data.identification,
            "$phone": data.phoneNumber,
            "phoneNumber": data.phoneNumber,
            "$email": data.email,
            "dob": data.dob,
            "status": "success",
          });
          dispatch({ type: SET_DIALOG,
            data: {
              dialog: {
                dialogTitle: "Success",
                // dialogContent: "Your application for Aspirasi C-Protect insurance is successful and you will be charged RM1 on 15 April 2020 for this month's subscription<br/><br/>Permohonan insurans Aspirasi C-Protect anda berjaya dan anda akan dicaj RM1 pada 15 April 2020 untuk langganan bulan ini.",
                dialogContent: "Your message will be delivered shortly.<br/><br/>Mesej anda akan dihantar sebentar lagi.",
                returnHome: true,
              },
              show: true,
            } });
        } else {
          ReactGA.event({ category: "Insurance cProtect", action: "Failure" });
        }
      }).catch((error) => {
        ReactGA.event({ category: "Insurance cProtect", action: "Failure" });
        mixpanel.identify(data.transactionId);
        mixpanel.track("save-c-protect", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.transactionId,
          "transactionId": data.transactionId,
          "$name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "phoneNumber": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    saveCelcomCProtectRxApplication: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/save-c-protect-rx", data).then((res) => {
        // analytics
        deviceInfo("INSURANCE", "SIGN UP - CELCOM C PROTECT RX", res.data.data.applicationId);
        //
        if (res.status === 200) {
          ReactGA.event({ category: "Insurance cProtect", action: "Success" });
          mixpanel.identify(data.transactionId);
          mixpanel.track("save-c-protect-rx", { "status": "success" });
          mixpanel.people.set({
            "$distinct_id": data.transactionId,
            "transactionId": data.transactionId,
            "status": "success",
          });
          dispatch({ type: SET_DIALOG,
            data: {
              dialog: {
                dialogTitle: "Success",
                // dialogContent: "Your application for Aspirasi C-Protect insurance is successful and you will be charged RM1 on 15 April 2020 for this month's subscription<br/><br/>Permohonan insurans Aspirasi C-Protect anda berjaya dan anda akan dicaj RM1 pada 15 April 2020 untuk langganan bulan ini.",
                dialogContent: "Your message will be delivered shortly.<br/><br/>Mesej anda akan dihantar sebentar lagi.",
                returnHome: true,
              },
              show: true,
            } });
        } else {
          ReactGA.event({ category: "Insurance cProtect", action: "Failure" });
        }
      }).catch((error) => {
        ReactGA.event({ category: "Insurance cProtect", action: "Failure" });
        mixpanel.identify(data.transactionId);
        mixpanel.track("save-c-protect-rx", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.transactionId,
          "transactionId": data.transactionId,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    saveSmeOwnerProtect3in1Application: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/save-smeownerprotect-3in1", data).then((res) => {
        // analytics
        deviceInfo("INSURANCE", "SIGN UP - SME TAKAFUL USAHAWAN 3-IN-1", res.data.data.applicationId);
        //
        mixpanel.alias(data.email);
        mixpanel.track("save-smeownerprotect-3in1", { "status": "success" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "planCode": data.planCode,
          "status": "success",
        });
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
        dispatch({ type: SET_STEP, data: 2 });
      }).catch((error) => {
        mixpanel.alias(data.email);
        mixpanel.track("save-smeownerprotect-3in1", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "$name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "planCode": data.planCode,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    saveSmeOwnerProtectApplication: (data, promptDialog) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/save-smeownerprotect", data).then((res) => {
        // analytics
        deviceInfo("INSURANCE", "SIGN UP - SME OWNER PROTECT", res.data.data.applicationId);
        //
        mixpanel.alias(data.email);
        mixpanel.track("save-smeownerprotect", { "status": "success" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "planCode": data.planCode,
          "status": "success",
        });
        if (!promptDialog) {
          dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
          dispatch({ type: SET_STEP, data: 2 });
        } else {
          dispatch({ type: SET_DIALOG,
            data: {
              dialog: {
                dialogTitle: "Success",
                dialogContent: "Thank you for your application. Your insurance policy will be activated after the payment(s) under your SME Digitalization Grant Registration form is processed and received and upon the payment of the premium sum to the Insurer. We will notify you once the Policy is activated.",
                trimQuote: true,
                refresh: true,
              },
              show: true
            } });
        }
      }).catch((error) => {
        mixpanel.alias(data.email);
        mixpanel.track("save-smeownerprotect", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "$name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    // saveCancerCarePrimeApplication: (data) => {
    //   dispatch({ type: IS_LOADING, data: true });
    //   return api.post("/ge/save-cancer-care-prime", data).then(res => {
    //     //analytics
    //     deviceInfo('INSURANCE','CANCER CARE PRIME', res.data.data.applicationId)
    //     //
    //     mixpanel.alias(data.email);
    //     mixpanel.track("save-cancer-care-prime", {"status": "success"});
    //     mixpanel.people.set({
    //       "$distinct_id": data.email,
    //       "name": data.name,
    //       "identificationType" : data.identificationType,
    //       "identification": data.identification,
    //       "$phone": data.phoneNumber,
    //       "$email": data.email,
    //       "dob":  data.dob,
    //       "status": "success",
    //     });
    //     if (!res.data.data.curlecParams){
    //       dispatch({ type: IS_LOADING, data: false });
    //       dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
    //       dispatch({ type: SET_STEP, data: 2});
    //     } else {
    //       return res.data.data.curlecParams;
    //     }
    //   }).catch(error => {
    //     mixpanel.alias(data.email);
    //     mixpanel.track("save-cancer-care-prime", {"status": "fail"});
    //     mixpanel.people.set({
    //       "$distinct_id": data.email,
    //       "$name": data.name,
    //       "identificationType" : data.identificationType,
    //       "identification": data.identification,
    //       "$phone": data.phoneNumber,
    //       "$email": data.email,
    //       "dob":  data.dob,
    //       "status": "fail",
    //       "errorMessage": JSON.stringify(error?.data?.error?.message),
    //     });
    //     dispatch({ type: IS_LOADING, data: false });
    //     dispatch({ type: SET_DIALOG, data: {
    //       dialog:{
    //         dialogTitle: "Error",
    //         dialogContent: error?.data?.error?.message
    //       },
    //       show: true
    //     }});

    //   }).finally(() => {
    //     // dispatch({ type: IS_LOADING, data: false });
    //   })
    // },
    saveCancerCarePrimeApplication: (data) => {
      console.log("saveCancerCarePrimeApplication");
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/save-cancer-care-prime", data).then((res) => {
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
        if (res.status === 200) {
          return true;
        }
        return false;
      }).catch((error) => {
        let lastError;
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message ? Object.values(error?.data?.error?.message).map((obj) => {
                if (obj !== lastError) {
                  lastError = obj;
                  return `${obj}<br/>`;
                }
              }).join("") : error?.data?.error?.message,
              trimQuote: true,
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    updateCancerCarePrime: (data) => {
      console.log("updateCancerCarePrime");
      dispatch({ type: IS_LOADING, data: true });
      return api.put(`/ge/update-cancer-care-prime/${state.payloadSaveApplication.applicationId}`, data).then((res) => {
        if (res.status === 200) {
          return true;
        }
        return false;
      }).catch((error) => {
        let lastError;
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message ? Object.values(error?.data?.error?.message).map((obj) => {
                if (obj !== lastError) {
                  lastError = obj;
                  return `${obj}<br/>`;
                }
              }).join("") : error?.data?.error?.message,
              trimQuote: true,
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    confirmCancerCarePrime: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/confirm-cancer-care-prime", data).then((res) => {
        deviceInfo("INSURANCE", "CANCER CARE PRIME", data.applicationId);
        mixpanel.alias(state.dataPremium.email);
        mixpanel.track("save-cancer-care-prime", { "status": "success" });
        mixpanel.people.set({
          "$distinct_id": state.dataPremium.email,
          "name": state.dataPremium.name,
          "identificationType": state.dataPremium.identificationType,
          "identification": state.dataPremium.identification,
          "$phone": state.dataPremium.phoneNumber,
          "$email": state.dataPremium.email,
          "dob": state.dataPremium.dob,
          "status": "success",
        });
        if (!res.data.data.curlecParams) {
          dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
          dispatch({ type: SET_STEP, data: 2 });
        } else {
          return res.data.data.curlecParams;
        }
      }).catch((error) => {
        mixpanel.alias(state.dataPremium.email);
        mixpanel.track("save-cancer-care-prime", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": state.dataPremium.email,
          "$name": state.dataPremium.name,
          "identificationType": state.dataPremium.identificationType,
          "identification": state.dataPremium.identification,
          "$phone": state.dataPremium.phoneNumber,
          "$email": state.dataPremium.email,
          "dob": state.dataPremium.dob,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        let lastError;
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message ? Object.values(error?.data?.error?.message).map((obj) => {
                if (obj !== lastError) {
                  lastError = obj;
                  return `${obj}<br/>`;
                }
              }).join("") : error?.data?.error?.message,
              trimQuote: true,
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    validateProtectSuper6: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/validate-protect-super6", data).then((res) => {
        dispatch({ type: SET_STEP, data: 1 });
      }).catch((error) => {
        if (error?.status === 302) {
          window.location.href = `/pasar/${PRODUCT_PROTECT_SUPER_6_PATH}/${error?.data?.error?.id}`;
        } else {
          dispatch({ type: SET_DIALOG,
            data: {
              dialog: {
                dialogTitle: "Error",
                dialogContent: error?.data?.error?.message ? Object.values(error?.data?.error?.message).map((obj) => { return obj; }).join("<br/>") : error?.data?.error?.message,
                trimQuote: true,
              },
              show: true
            } });
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    validateSmeOwnerProtect3in1: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/validate-smeownerprotect-3in1", data).then((res) => {
        dispatch({ type: SET_STEP, data: 1 });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message ? Object.values(error?.data?.error?.message).map((obj) => { return obj; }).join("<br/>") : error?.data?.error?.message,
              trimQuote: true,
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    validateSmeOwnerProtect: (data, stay) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/validate-smeownerprotect", data).then((res) => {
        if (stay) {
          return true;
        }
        dispatch({ type: SET_STEP, data: 1 });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message ? Object.values(error?.data?.error?.message).map((obj) => { return obj; }).join("<br/>") : error?.data?.error?.message,
              trimQuote: true,
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    validateHospiCash: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/validate-hospicash", data).then((res) => {
        dispatch({ type: SET_STEP, data: 1 });
      }).catch((error) => {
        if (error?.status === 302) {
          window.location.href = `/pasar/${PRODUCT_HOSPITAL_CASH_PATH}/${error?.data?.error?.id}`;
        } else {
          dispatch({ type: SET_DIALOG,
            data: {
              dialog: {
                dialogTitle: "Error",
                dialogContent: error?.data?.error?.message ? Object.values(error?.data?.error?.message).map((obj) => { return obj; }).join("<br/>") : error?.data?.error?.message,
                trimQuote: true,
              },
              show: true
            } });
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    validateProtect: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/validate-life-application", data).then((res) => {
        dispatch({ type: SET_STEP, data: 1 });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message ? Object.values(error?.data?.error?.message).map((obj) => { return obj; }).join("<br/>") : error?.data?.error?.message,
              trimQuote: true,
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    validateProtectBoost: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/validate-boost-protect", data).then((res) => {
        // dispatch({ type: SET_STEP, data: 1});
        return true;
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message ? Object.values(error?.data?.error?.message).map((obj) => { return obj; }).join("<br/>") : error?.data?.error?.message,
              trimQuote: true,
            },
            show: true
          } });
        return false;
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    validateBillProtect: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/validate-bill-protect", data).then((res) => {
        dispatch({ type: SET_STEP, data: 1 });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message ? Object.values(error?.data?.error?.message).map((obj) => { return obj; }).join("<br/>") : error?.data?.error?.message,
              trimQuote: true,
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    validateCardProtect: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/validate-card-protect", data).then((res) => {
        dispatch({ type: SET_STEP, data: 1 });
      }).catch((error) => {
        if (error?.status === 302) {
          window.location.href = `/pasar/${PRODUCT_CARDPROTECT_PATH}/${error?.data?.error?.id}`;
        } else {
          dispatch({ type: SET_DIALOG,
            data: {
              dialog: {
                dialogTitle: "Error",
                dialogContent: error?.data?.error?.message ? Object.values(error?.data?.error?.message).map((obj) => { return obj; }).join("<br/>") : error?.data?.error?.message,
                trimQuote: true,
              },
              show: true
            } });
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    validateCancerCarePrime: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/validate-cancer-care-prime", data).then((res) => {
        // dispatch({ type: SET_STEP, data: 1});
        if (res.status === 200) {
          return true;
        }
        return false;
      }).catch((error) => {
        let lastError;
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message ? Object.values(error?.data?.error?.message).map((obj) => {
                if (obj !== lastError) {
                  lastError = obj;
                  return `${obj}<br/>`;
                }
              }).join("") : error?.data?.error?.message,
              trimQuote: true,
            },
            show: true
          } });
        return false;
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    getCancerCarePrimePlanPrice: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/get-cancer-care-prime-plan-price", data).then((res) => {
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadCancerCarePrimePlan" });
        dispatch({ type: SET_STEP, data: 1 });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    requestEGHLparams: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.postToPayment("/REST-GE/services/requestEghlParams", data).then((res) => {
        dispatch({ type: SET_DATA, data: res.data, name: "payloadEGHLparams" });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: "Something went wrong, Please try again later.",
              trimQuote: true,
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    requestBoostparams: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.postToPayment("/REST-GE/services/boost/requestBoostParams", data).then((res) => {
        dispatch({ type: SET_DATA, data: res.data, name: "payloadEGHLparams" });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: "Something went wrong, Please try again later.",
              trimQuote: true,
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    paymentStatus: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/payment-status", data).then((res) => {
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadPaymentStatus" });
      }).catch((error) => {
        if (error.status === 420) {
          dispatch({ type: SET_DATA, data: error.data.error, name: "payloadPaymentStatus" });
        } else {
          dispatch({ type: SET_DATA, data: error.data.error, name: "payloadPaymentStatus" });
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    paymentCurlecStatus: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/mandate-status", data).then((res) => {
        dispatch({ type: SET_DATA, data: { status: data.status === 1 ? 2 : data.status }, name: "payloadPaymentStatus" });
      }).catch((error) => {
        if (error.status === 420) {
          dispatch({ type: SET_DATA, data: { status: data.status === 1 ? 2 : data.status }, name: "payloadPaymentStatus" });
        } else {
          dispatch({ type: SET_DATA, data: { status: data.status === 1 ? 2 : data.status }, name: "payloadPaymentStatus" });
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    setDialog: (data) => {
      dispatch({ type: SET_DIALOG, data });
    },
    setUtm: (data) => {
      // dispatch({ type: IS_LOADING, data: true });
      // return api.post("/ge/campaign-tracker", data).then(res => {
      // }).catch(error => {
      //   // dispatch({ type: IS_LOADING, data: false });
      // }).finally(() => {
      //   dispatch({ type: IS_LOADING, data: false });
      // });
    },
    getRenewHospicash: (code) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.get(`/ge/renew-hospicash/${code}`).then((res) => {
        const data = {
          dataForm: { ...res.data.data },
          dataPremium: { ...res.data.data }
        };
        dispatch({ type: SET_DATA, data });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Important",
              dialogContent: `You are about to purchase Aspirasi HospiCash during your grace period. Your current active policy is from ${res.data.data.policyEffectiveDate} until ${res.data.data.policyEndDate}. Your renewal policy date for Aspirasi HospiCash will take effect from ${res.data.data.nextPolicyEffectiveDate} until ${res.data.data.nextPolicyEndDate}.`,
              trimQuote: true,
            },
            show: true
          } });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    renewHospicash: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/renew-hospicash", { id: data.id }).then((res) => {
        // analytics
        deviceInfo("INSURANCE", "RENEW - HOSPICASH", res.data.data.applicationId);
        //
        mixpanel.alias(data.email);
        mixpanel.track("renew-hospicash", { "status": "success" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "success",
        });
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
        dispatch({ type: SET_STEP, data: 1 });
      }).catch((error) => {
        mixpanel.alias(data.email);
        mixpanel.track("renew-hospicash", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "$name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    getRenewBillProtect: (code) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.get(`/ge/renew-bill-protect/${code}`).then((res) => {
        const data = {
          dataForm: { ...res.data.data },
          dataPremium: { ...res.data.data }
        };
        dispatch({ type: SET_DATA, data });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    renewBillProtect: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/renew-bill-protect", { id: data.id }).then((res) => {
        // analytics
        deviceInfo("INSURANCE", "RENEW - BILL PROTECT", res.data.data.applicationId);
        //
        mixpanel.alias(data.email);
        mixpanel.track("renew-bill-protect", { "status": "success" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "success",
        });
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
        dispatch({ type: SET_STEP, data: 1 });
      }).catch((error) => {
        mixpanel.alias(data.email);
        mixpanel.track("renew-bill-protect", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "$name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    getRenewCardProtect: (code) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.get(`/ge/renew-card-protect/${code}`).then((res) => {
        const data = {
          dataForm: { ...res.data.data },
          dataPremium: { ...res.data.data }
        };
        console.log("res.", res);
        dispatch({ type: SET_DATA, data });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Important",
              dialogContent: `You are about to purchase CardProtect during your grace period. Your current active policy is from ${res.data.data.policyEffectiveDate} until ${res.data.data.policyEndDate}. Your renewal policy date for CardProtect will take effect from ${res.data.data.nextPolicyEffectiveDate} until ${res.data.data.nextPolicyEndDate}.`,
              trimQuote: true,
            },
            show: true
          } });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    renewCardProtect: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/renew-card-protect", { id: data.id }).then((res) => {
        // analytics
        deviceInfo("INSURANCE", "RENEW - CARD PROTECT", res.data.data.applicationId);
        //
        mixpanel.alias(data.email);
        mixpanel.track("renew-card-protect", { "status": "success" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "success",
        });
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
        dispatch({ type: SET_STEP, data: 1 });
      }).catch((error) => {
        mixpanel.alias(data.email);
        mixpanel.track("renew-card-protect", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "$name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    getRenewProtectSuper6: (code) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.get(`/ge/renew-protect-super6/${code}`).then((res) => {
        const data = {
          dataForm: { ...res.data.data },
          dataPremium: { ...res.data.data }
        };
        dispatch({ type: SET_DATA, data });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Important",
              dialogContent: `You are about to purchase Aspirasi Protect Super6 during your grace period. Your current active policy is from ${res.data.data.policyEffectiveDate} until ${res.data.data.policyEndDate}. Your renewal policy date for Aspirasi Protect Super6 will take effect from ${res.data.data.nextPolicyEffectiveDate} until ${res.data.data.nextPolicyEndDate}.`,
              trimQuote: true,
            },
            show: true
          } });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    renewProtectSuper6: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/renew-protect-super6", { id: data.id }).then((res) => {
        mixpanel.alias(data.email);
        mixpanel.track("renew-protect-super6", { "status": "success" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "success",
        });
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
        dispatch({ type: SET_STEP, data: 1 });
      }).catch((error) => {
        mixpanel.alias(data.email);
        mixpanel.track("renew-protect-super6", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "$name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    getRenewSmeOwnerProtect3In1: (code) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.get(`/ge/renew-smeownerprotect-3in1/${code}`).then((res) => {
        const data = {
          dataForm: { ...res.data.data },
          dataPremium: { ...res.data.data }
        };
        dispatch({ type: SET_DATA, data });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    renewSmeOwnerProtect3in1: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/renew-smeownerprotect-3in1", { id: data.id }).then((res) => {
        mixpanel.alias(data.email);
        mixpanel.track("renew-smeownerprotect-3in1", { "status": "success" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "success",
        });
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
        dispatch({ type: SET_STEP, data: 1 });
      }).catch((error) => {
        mixpanel.alias(data.email);
        mixpanel.track("renew-smeownerprotect-3in1", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "$name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    getRenewSmeOwnerProtect: (code) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.get(`/ge/renew-smeownerprotect/${code}`).then((res) => {
        const data = {
          dataForm: { ...res.data.data },
          dataPremium: { ...res.data.data }
        };
        dispatch({ type: SET_DATA, data });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    renewSmeOwnerProtect: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/ge/renew-smeownerprotect", { id: data.id }).then((res) => {
        mixpanel.alias(data.email);
        mixpanel.track("renew-smeownerprotect", { "status": "success" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "success",
        });
        dispatch({ type: SET_DATA, data: res.data.data, name: "payloadSaveApplication" });
        dispatch({ type: SET_STEP, data: 1 });
      }).catch((error) => {
        mixpanel.alias(data.email);
        mixpanel.track("renew-smeownerprotect", { "status": "fail" });
        mixpanel.people.set({
          "$distinct_id": data.email,
          "$name": data.name,
          "identificationType": data.identificationType,
          "identification": data.identification,
          "$phone": data.phoneNumber,
          "$email": data.email,
          "dob": data.dob,
          "status": "fail",
          "errorMessage": JSON.stringify(error?.data?.error?.message),
        });
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: error?.data?.error?.message
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    getBankList: () => {
      dispatch({ type: IS_LOADING, data: true });

      return api.postToCurlec("/curlec-services/banks?method=00&msgToken=01").then((res) => {
        if (res.data.Status[0] !== "201") {
          dispatch({ type: SET_DIALOG,
            data: {
              dialog: {
                dialogTitle: "Payment gateway Error",
                dialogContent: `${res.data.Message[0]}}`,
                trimQuote: true,
              },
              show: true
            } });
          return;
        }
        const B2C = "Personal";
        const bankListOne = res.data.Response[0].map((item) => ({ id: item.id[0], name: `${item.name[0]} (${B2C})` }));
        dispatch({ type: SET_DATA, data: { bankArr: [...bankListOne] } });
      }).catch((error) => {
        dispatch({ type: SET_DIALOG,
          data: {
            dialog: {
              dialogTitle: "Error",
              dialogContent: "Something went wrong, Please try again later.",
            },
            show: true
          } });
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });

      // const getBanklistTokenOne = api.postToCurlec("/curlec-services/banks?method=00&msgToken=01");
      // const getBanklistTokenTwo = api.postToCurlec("/curlec-services/banks?method=00&msgToken=02");
      // const B2C = "Personal";
      // const B2B = "Business";

      // return Promise.all([getBanklistTokenOne, getBanklistTokenTwo])
      //   .then(([banklistTokenOne, banklistTokenTwo]) => {
      //     // handle payment gateway server maintenance
      //     if (banklistTokenOne.data.Status[0] !== "201" || banklistTokenTwo.data.Status[0] !== "201") {
      //       dispatch({ type: SET_DIALOG, data: {
      //         dialog:{
      //           dialogTitle: "Payment gateway Error",
      //           dialogContent: `${banklistTokenOne.data.Message[0]}, ${banklistTokenTwo.data.Message[0]}`,
      //           trimQuote: true,
      //         },
      //         show: true
      //       }});
      //       return;
      //     }

      //     const bankListOne = banklistTokenOne.data.Response[0].map((item) => ({ id: item.id[0], name: `${item.name[0]} (${B2C})` }));
      //     const bankListTwo = banklistTokenTwo.data.Response[0].map((item) => ({ id: item.id[0], name: `${item.name[0]} (${B2B})` }));
      //     dispatch({ type: SET_DATA, data: { bankArr: [...bankListOne, ...bankListTwo] } });
      //   }).catch(error => {
      //     console.log("getbank", error);
      //     dispatch({ type: SET_DIALOG, data: {
      //       dialog:{
      //         dialogTitle: "Error",
      //         dialogContent: "Something went wrong, Please try again later.",
      //       },
      //       show: true
      //     }});
      //   }).finally(() => {
      //     dispatch({ type: IS_LOADING, data: false });
      //   });
    },
  };
};
