import styled, { keyframes, css } from "styled-components";
import { headerHeight } from "../Header/Styled";

const fadeIn = keyframes(css`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`);

export const SidePopupWrapper = styled("div")`
  height: 50px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${(props) => `url(${props.background})`};
  background-color: #ddd;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
  transform: translateX(0);
  @media screen and (max-width: 900px) {
    background-image: ${(props) => `url(${props.backgroundMobile})`};
  }
  &.fading {
    opacity: 0;
    &.left {
      transform: translateX(-100%);
    }
    &.right {
      transform: translateX(100%);
    }
  }
  &.fade-in {
    opacity: 0;
    animation: ${fadeIn} 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
  &.top {
    top: ${headerHeight}px;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.16);
    .close-ads {
      bottom: -12px;
    }
  }
  &.left {
    left: 0;
    .close-ads {
      right: -12px;
    }
  }
  &.right {
    right: 0;
    .close-ads {
      left: -12px;
    }
  }
  &.bottom {
    bottom: 0;
    box-shadow: 0 -3px 16px rgba(0, 0, 0, 0.16);
    .close-ads {
      top: -12px;
    }
  }
  &.middle {
    width: 480px;
    height: 480px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border-radius: 8px;
    &:before {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
    }
    .close-ads {
      top: -12px;
      right: -12px;
    }
  }
  > a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-decoration: none;
  }
  .close-ads {
    position: absolute;
    background: #fff;
    outline: none;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #102938;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    > svg {
      font-size: 16px;
      color: #102938;
    }
  }
`;
