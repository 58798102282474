import * as React from "react";
import { FaTimes } from "react-icons/fa";
import { MiddlePopupWrapper } from "./Styled";
// import { Link } from "react-router-dom";
import Link from '../../Public/Util/Link'

const initialState = {
  animate: true,
  show: true,
  scrollShow: true
};

class MiddlePopup extends React.Component {
  callbackTimeout;
  autoCloseTimeout;
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  closePopup = () => {
    document.documentElement.style.overflow = '';
    sessionStorage["alreadyVisited"] = true;
    const { callback } = this.props;
    const that = this;
    this.setState({
      animate: false
    });
    this.callbackTimeout = setTimeout(function() {
      // callback();
      that.setState({
        show: false
      });
    }, 400);
  };

  firstVisit = () => {
    let visited = sessionStorage["alreadyVisited"]
    const that = this;

    if (visited){
      that.setState({
        show: false
      });
    }
    else {
      document.documentElement.style.overflow = 'hidden';
    }
  }

  handleScroll = () => {
    this.setState({
      scrollShow: true
    });
    window.removeEventListener("scroll", this.handleScroll);
  };

  componentDidMount() {
    if (this.props.oneTime){
      this.firstVisit();
    }
    
    const { showType, showTypeOption } = this.props;
    const that = this;
    if (showType === "on-time") {
      const countdown = Number(showTypeOption);
      this.autoCloseTimeout = setTimeout(function() {
        that.closePopup();
      }, countdown * 1000);
    } else if (showType === "on-scroll") {
      this.setState({
        scrollShow: false
      });
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  componentWillUnmount() {
    const { showType } = this.props;
    if (showType === "on-scroll") {
      window.removeEventListener("scroll", this.handleScroll);
    }
    clearTimeout(this.callbackTimeout);
    clearTimeout(this.autoCloseTimeout);
    this.setState(initialState);
  }

  render() {
    const {
      img,
      imageMobile,
      position,
      url,
      newTab,
      title,
      textContent,
      showType,
    } = this.props;
    const { animate, show, scrollShow } = this.state;
    const isExternalLink = url && url.startsWith("http");

    return (
      show &&
      scrollShow && (
        <MiddlePopupWrapper
          className={`${position} ${animate ? "" : "fading"} ${
            showType === "on-entry" ? "fade-in" : ""
          }`}
          background={img}
          backgroundMobile={imageMobile}
          textContent={textContent}
        >
          <div className="content">
            {url != null ?
              isExternalLink ? (
                <a href={url} target={newTab ? "_blank" : ""} />
              ) : (
                <Link to={url} target={newTab ? "_blank" : ""} />
              )
            : null}
            {textContent ?
              <div className="text-container">
                <div className="text-content">
                {textContent.map((text)=>{
                  return (
                    <p>
                      {text}
                    </p>
                  )}
                )}
                </div>
              </div> : ''
            } 
            <button className="close-ads" onClick={this.closePopup}>
              <FaTimes />
            </button>
          </div>
        </MiddlePopupWrapper>
      )
    );
  }
}
export default MiddlePopup;
